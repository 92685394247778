import { FC, ReactNode } from "react";
import styled from "@emotion/styled";

type CardProps = {
  children: ReactNode;
};

const BaseCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  color: #4f4f4f;
  font-size: 1rem;
  padding: 1.2rem 1rem;
  width: 100%;
  font-family: "Roboto", sans-serif;
  display: block;
  position: relative;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Card: FC<CardProps> = (props) => {
  return <BaseCard>{props.children}</BaseCard>;
};

export default Card;
