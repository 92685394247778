type FlexDirection = "row" | "column" | "row-reverse" | "column-reverse";

type BoxProps = {
  children: React.ReactNode;
  row?: boolean;
  column?: boolean;
  reverse?: boolean;
  center?: boolean;
  gap?: string;
  padding?: string;
  margin?: string;
  flex?: number;
  width?: string;
  height?: string;
  maxWidth?: string;
  minWidth?: string;
  wrap?: boolean;
  justifyContent?: string;
  alignItems?: string;
  overflowHidden?: boolean;
};

const Box = (props: BoxProps) => {
  const {
    children,
    row,
    column,
    reverse,
    center,
    gap,
    padding,
    margin,
    flex,
    width,
    height,
    maxWidth,
    wrap,
    minWidth,
    overflowHidden,
  } = props;
  let { justifyContent, alignItems } = props;

  let flexDirection: FlexDirection = "row";
  if (column) flexDirection = "column";
  if (reverse) flexDirection += "-reverse";

  if (center) {
    justifyContent = "center";
    alignItems = "center";
  }

  const style = {
    display: "flex",
    flexDirection: flexDirection as FlexDirection,
    justifyContent,
    alignItems,
    gap: gap || 0,
    padding: padding || 0,
    margin: margin || 0,
    flex: flex || 0,
    width: width || "auto",
    height: height || "auto",
    maxWidth: maxWidth || "auto",
    flexWrap: wrap ? "wrap" : ("nowrap" as "wrap" | "nowrap"),
    whiteSpace: wrap !== false ? "normal" : ("nowrap" as "normal" | "nowrap"),
    minWidth: minWidth || "auto",
    overflow: overflowHidden ? "hidden" : "visible",
  };

  return <div style={style}>{children}</div>;
};

export default Box;
