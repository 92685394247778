import { useMemo } from "react";
import { Sentiment, Student, StudyContext } from "../types";
import useGradeSentiment from "./useGradeSentiment";

const phraseRecord: Record<Sentiment, string[]> = {
  1: ["unsatisfactory", "very poor", "disappointing"],
  2: ["poor", "not up to standard", "concerning"],
  3: ["infrequent", "could do better", "needs to improve"],
  4: ["sound", "doing alright", "needs to focus"],
  5: ["acceptable", "doing okay", "keep trying"],
  6: ["good", "doing well", "keep it up"],
  7: ["very good", "so proud", "great effort"],
  8: ["excellent", "fantastic", "brilliant"],
  9: ["exceptional", "super", "exemplary"],
};

const useAdditionalComments = (student: Student): string => {
  if (!student.comments) {
    return "";
  }
  return `In your own words, include these comments: ${student.comments}.`;
};

const useGradeStatement = (student: Student, context: StudyContext): string => {
  if (!student.grade && !student.gradeSentiment) {
    return "";
  }

  const noun = student.pronoun ? student.pronoun : student.name;

  const sentiment: Sentiment = (() => {
    if (student.gradeSentiment) {
      return student.gradeSentiment;
    }
    if (student.grade && context.gradeSystem) {
      return useGradeSentiment(student.grade, context.gradeSystem) ?? 6;
    }
    return 6;
  })();

  const comment = (() => {
    switch (sentiment) {
      case 1:
        return `very concerned about ${noun}'s exam results`;
      case 2:
        return `concerned about ${noun}'s exam results`;
      case 3:
        return `a little concerned about ${noun}'s exam results`;
      case 4:
        return `could do better`;
      case 5:
        return `room for improvement`;
      case 6:
        return `pleased with ${noun}'s academic acheivement in this year`;
      case 7:
        return `${noun} should be commended for their academic acheivement`;
      case 8:
        return `great grades`;
      case 9:
        return `outstanding grades`;
      default:
        return "";
    }
  })();

  return `Include a paragraph about ${student.name} achieving an exam grade of ${student.grade} in ${context.subject}, ${comment}.`;
};

const useHomeworkStatement = (student: Student): string => {
  if (!student.homework) {
    return "";
  }
  // const noun = student.pronoun ? student.pronoun : student.name;
  // const sentiment = craftSentiment(student.homework, noun, "homework");
  return `Include a paragraph about ${student.name}'s ${
    phraseRecord[student.homework][0]
  } homework, ${phraseRecord[student.homework][1]}.`;
};

const useAttendanceStatement = (student: Student): string => {
  if (!student.attendance) {
    return "";
  }
  // const noun = student.pronoun ? student.pronoun : student.name;
  // const sentiment = craftSentiment(student.attendance, noun, "attendance");
  return `Include a paragraph about ${student.name}'s ${
    phraseRecord[student.attendance][0]
  } class attendance, ${phraseRecord[student.attendance][2]}.`;
};

const useParticipationStatement = (student: Student): string => {
  if (!student.participation) {
    return "";
  }
  const noun = student.pronoun ? student.pronoun : student.name;

  const sentiment = (() => {
    switch (student.participation) {
      case 1:
        return `${noun} is reluctant to learn and often resists trying new things or engaging in new tasks`;
      case 2:
        return `${noun} struggles to focus and pay attention when learning, and often gets frustrated or overwhelmed easily`;
      case 3:
        return `${noun} is uninterested in learning and often finds excuses not to engage in class`;
      case 4:
        return `${noun} is able to focus and concentrate when learning, and is willing to put in the effort to understand new concepts`;
      case 5:
        return `${noun} is eager to learn and is always asking questions or seeking clarification when needed`;
      case 6:
        return `${noun} is motivated to learn and is always looking for new opportunities to improve their knowledge`;
      case 7:
        return `${noun} is a great example to others, and is always willing to help others learn`;
      case 8:
        return `${noun} loves learning and is always looking for new opportunities to improve their knowledge`;
      case 9:
        return `${noun} loves learning and is always looking for new opportunities to improve their knowledge`;
      default:
        return "";
    }
  })();

  return `Include a paragraph about ${student.name}'s ${
    phraseRecord[student.participation][0]
  } participation in the classroom, ${sentiment}.`;
};

const useFirstStatement = (student: Student, context: StudyContext): string => {
  const prefix = `Write a school report about ${student.name} studying ${context.subject}.`;
  const suffix =
    `The first paragraph should reference ${student.name}'s progress in ${context.subject} this year. ` +
    `Inform of ${student.name}'s performance at school, any areas for improvement and celebrate any achievements.`;
  if (!student.pronoun) {
    return `${prefix} Use the third person, and never make reference to their teacher. ${suffix}`;
  }
  return `${prefix} Use the third person, after the first paragraph refer to ${student.name} as ${student.pronoun}, and never make reference to their teacher. ${suffix}`;
};

const useLastStatement = (args: Student): string => {
  return `The last paragraph should be an optimistic conclusion to the school report.`;
};

const useCraftPrompt = (
  student: Student | null,
  context: StudyContext
): string =>
  useMemo(() => {
    if (student == null || !student.name || !context.subject) {
      return "";
    }
    const firstStatement = useFirstStatement(student, context);
    const attendanceStatement = useAttendanceStatement(student);
    const participationStatement = useParticipationStatement(student);
    const homeworkStatement = useHomeworkStatement(student);
    const gradeStatement = useGradeStatement(student, context);
    const additionalComments = useAdditionalComments(student);
    const lastStatement = useLastStatement(student);

    const statements = [
      firstStatement,
      attendanceStatement,
      participationStatement,
      homeworkStatement,
      gradeStatement,
      additionalComments,
      lastStatement,
    ];

    const filteredStatements = statements.filter(
      (statement) => statement !== ""
    );

    return filteredStatements.join(" ");
  }, [student, context]);

export default useCraftPrompt;
