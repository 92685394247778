import { Global } from "@emotion/react";
import { FC } from "react";

const Reset: FC = (props) => {
  return (
    <Global
      styles={`
                * {
                    box-sizing: border-box;
                }
                body {
                    margin: 0;
                    padding: 0;
                }
                a {
                  color: #222;
                  text-decoration: underline;
                  text-decoration-color: #222;
                }
            `}
    />
  );
};

export default Reset;
