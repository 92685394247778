import styled from "@emotion/styled";
import { ReactElement, useEffect, useMemo, useRef } from "react";

const Table = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  width: 100%;
  height: 20rem;
  background-color: #f4f4f4;
  overflow-y: scroll;
  overflow-x: auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 50rem;
`;

const RowHeader = styled(Row)`
  position: sticky;
  top: 0;
  z-index: 1;
`;

type CellProps = {
  header?: boolean;
  flex?: number;
  active?: boolean;
};

const Cell = styled.div<CellProps>`
  display: flex;
  flex: ${(props) => props.flex || 1};
  align-content: center;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: ${(props) => (props.header ? "bold" : "normal")};

  border: 1px solid #ccc;

  padding: 0.5rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  background-color: ${(props) => (props.active ? "#ffe" : "#fff")};
`;

const CellHeader = Cell.withComponent("div");

type Column<T> = {
  flex?: number;
  label: string;
  key: keyof T;
};

type SpreadsheetProps<T> = {
  columns: Column<T>[];
  data: T[];
  activeId?: string;
};

const Spreadsheet = <T extends { id: string }>(
  props: SpreadsheetProps<T>
): ReactElement => {
  const activeRowRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (activeRowRef.current && tableRef.current) {
      const activeRow = activeRowRef.current;
      const table = tableRef.current;

      const activeRowTop = activeRow.offsetTop;
      table.scrollTop = activeRowTop - table.offsetTop - table.offsetHeight / 2;
    }
  }, [props.activeId]);

  return (
    <Table role="grid" ref={tableRef}>
      <RowHeader>
        {props.columns.map((column) => (
          <CellHeader flex={column.flex} key={column.key as string}>
            {column.label}
          </CellHeader>
        ))}
      </RowHeader>
      {props.data.map((row) => (
        <Row key={row.id}>
          {props.columns.map((column) => (
            <Cell
              key={`${row.id}-${column.key.toString()}`}
              flex={column.flex}
              active={props.activeId === row.id}
              ref={props.activeId === row.id ? activeRowRef : undefined}
            >
              {row[column.key]?.toString() ?? ""}
            </Cell>
          ))}
        </Row>
      ))}
    </Table>
  );
};

export default Spreadsheet;
export type { Column };
