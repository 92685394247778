import { FC, useEffect } from "react";
import { Heading1, Heading2, Box, Paragraph, Span, SpacedParagraph, Heading3 } from "../../components";
import { Link } from "react-router-dom";

const AreSchoolReportsWorthItBlogPostView: FC = () => {
    useEffect(() => {
        document.title = "Reports Writer | Are School Reports Worth It?";
    }, []);

    return (
        <Box column gap="1rem" flex={1} padding="4rem" maxWidth="40rem" margin="0 auto">
            <Box row>
                <Link to="/blog"><Span>Back</Span></Link>
            </Box>
            <Heading1>Are School Reports Worth It?</Heading1>
            <Box row justifyContent="space-between">
                <Paragraph>
                    by James O'Toole
                </Paragraph>
                <Paragraph>
                    3rd Nov 2022
                </Paragraph>
            </Box>
            <SpacedParagraph>
                School reports are a great way to engage parents with their student's education and school community.
                Reports provide parents with an overview of their child's progress and areas of improvement, and they help identify any areas where the child may need extra support.
            </SpacedParagraph>
            <SpacedParagraph>
                However, writing reports is very time consuming, especially if they are personalised and relevant to the student.
                Some schools have stopped doing school reports, because it takes time to write them, but this means that parents suffer and are less informed about their students' education.
            </SpacedParagraph>
            <SpacedParagraph>
                Fee-paying schools often value school reports higher, because the parents are also paying customers that expect feedback. This means that private schools are more likely to invest the resources necessary to write high quality reports.
            </SpacedParagraph>
            <SpacedParagraph>
                There are many advantages to school teachers writing school reports about their students and sending them home to their parents.
                It allows parents to be more involved in their child's education and to better understand what is going on in the classroom.
                School reports can help parents to identify areas where a child may need extra help or support.
            </SpacedParagraph>
            <SpacedParagraph>
                Further, school reports can help to build a stronger relationship between the teacher and the parent.
                A regular reason to communicate with parents is a great way to build trust and rapport.
                Helping the teacher to get feedback from the parent about how the child is doing in school.
            </SpacedParagraph>
            <SpacedParagraph>
                Overall, school reports are very valuable for parents and schools should assign the appropriate resources to write high quality school reports for their students.
            </SpacedParagraph>
            <Heading3>Read Next</Heading3>
            <Box column gap="1rem">
                <Paragraph>
                    <Link to="/blog/the-problem-with-report-comment-banks">
                        The Problem With Report Comment Banks
                    </Link>
                </Paragraph>
                <Paragraph>
                    <Link to="/blog/how-much-time-do-teachers-spend-on-reports">
                        How Much Time Do Teachers Spend On Reports?
                    </Link>
                </Paragraph>
            </Box>
        </Box>
    );
};

export { AreSchoolReportsWorthItBlogPostView };
