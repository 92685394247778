import styled from "@emotion/styled";
// six headings, each with a different size
// paragraph
// span

const base = `
    font-family: 'Roboto', sans-serif;
    color: #222;
    margin: 0;
    padding: 0;
    text-decoration-color: #222;
`;

const Heading1 = styled.h1`
  ${base}
  font-weight: 700;
  font-size: 2rem;
`;

const Heading2 = styled.h2`
  ${base}
  font-weight: 700;
  font-size: 1.5rem;
`;

const Heading3 = styled.h3`
  ${base}
  font-weight: 700;
  font-size: 1.25rem;
`;

const Heading4 = styled.h4`
  ${base}
  font-weight: 700;
  font-size: 1rem;
`;

const Heading5 = styled.h5`
  ${base}
  font-weight: 700;
  font-size: 0.875rem;
`;

const Heading6 = styled.h6`
  ${base}
  font-weight: 700;
  font-size: 0.75rem;
`;

const Paragraph = styled.p`
  ${base}
  font-size: 1rem;
  font-weight: 400;
`;

const SpacedParagraph = styled(Paragraph)`
  margin-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.8;
`;

const Span = styled.span`
  ${base}
  font-size: 1rem;
  font-weight: 400;
`;

const Strong = styled.strong`
  ${base}
  font-size: 1rem;
  font-weight: 700;
`;

const Em = styled.em`
  ${base}
  font-size: 1rem;
  font-style: italic;
`;

const Small = styled.small`
  ${base}
  font-size: 0.875rem;
`;

const Code = styled.code`
  ${base}
  font-size: 0.875rem;
  font-family: "Roboto Mono", monospace;
`;

export {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Paragraph,
  Span,
  Strong,
  Em,
  Small,
  Code,
  SpacedParagraph,
};
