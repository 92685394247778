import { FC, useCallback, useEffect, useState } from "react";
import {
  Box,
  Spreadsheet,
  Card,
  Carousel,
  Heading3,
  Heading4,
  Small,
  DateString,
  TimeString,
  Paragraph,
  Column,
} from "../components";
import { ID } from "../utils";
import { useSwipeable } from "react-swipeable";

const now = new Date();

type DataRow = [
  string,
  string,
  "he" | "she" | "they",
  number,
  number,
  number,
  number,
  string,
  number,
  string,
  string
];

const studentData: DataRow[] = [
  [
    "Mary",
    "Anning",
    "she",
    3,
    3,
    8,
    4,
    "loves bones and fossils",
    4,
    "Mary has made good progress in History this year. She is a bright girl who loves bones and fossils, and is always looking for new opportunities to improve her knowledge. However, Mary's infrequent class attendance and homework completion could be better. Mary achieved an exam grade of 4 in History, which is a good result, but she could do better.",
    "",
  ],
  [
    "Nikolaus",
    "August Otto",
    "he",
    8,
    6,
    4,
    4,
    "an explosive character",
    4,
    "Nikolaus has made excellent progress in History this year. He has shown a keen interest in the subject and has achieved a grade of 4 in his exams. Nikolaus is an excellent student who is always willing to put in the effort to understand new concepts. He is a sound participant in the classroom and has a great deal of focus and concentration. Nikolaus's homework is always of a high standard and he is a great asset to the class. Nikolaus is an explosive character who is always keen to learn more about History. He is a great student who has a bright future ahead of him.",
    "",
  ],
  // ["Jane","Austen","she",4,3,2,6,"day dreamer",6,"Jane has made good progress in History this year. She has performed well at school, although she has missed a few classes and needs to improve her participation in the classroom. Jane's homework is sound, and she is doing alright. She achieved an exam grade of 6 in History, which is pleasing. However, Jane is a day dreamer and can struggle to focus and pay attention when learning. She often gets frustrated or overwhelmed easily.",""  ],
  [
    "Francis",
    "Bacon",
    "he",
    5,
    6,
    7,
    6,
    "very intelligent",
    6,
    "Francis is a very intelligent student who has made great progress in History this year. He has been a great example to others in the classroom and is always willing to help others learn. Francis's homework has been acceptable this year and he has achieved an exam grade of 6 in History. Francis is a hard-working student with a bright future ahead of him.",
    "",
  ],
  // ["Simon","Bolivar","he",7,4,9,5,"very bold a striving young individual",5,"Simon has made excellent progress in History this year. He is a bright and enthusiastic student who loves learning. His class attendance is sound and he is always looking for new opportunities to improve his knowledge. His homework is very good and he is always willing to help other students in the class. Simon achieved an exam grade of 5 in History, which demonstrates his excellent understanding of the subject. Simon is a striving young individual who is always looking for ways to improve his performance. With continued hard work and dedication, Simon is sure to achieve great things in the future.","Simon has made excellent progress in History this year. He has performed well at school and is always looking for new opportunities to improve their knowledge. Simon's sound class attendance and very good homework have contributed to his excellent progress in History. Simon achieved an exam grade of 5 in History, room for improvement. Simon is a very bold and striving young individual who is always looking for new opportunities to improve their knowledge."  ],
  [
    "Coco",
    "Chanel",
    "she",
    8,
    8,
    7,
    8,
    "needs to learn to conform to the school's uniform policy",
    8,
    "Coco has progressed well in History this year and is a credit to the class. She is a very good example to others and is always willing to help others learn. Her homework is always of a high standard and she has achieved excellent grades in her exams. However, Coco needs to learn to conform to the school's uniform policy. She has been warned on several occasions about her appearance and has been asked to change her clothes. Overall, Coco is a credit to the class and is a great example to others. She has achieved excellent grades in her exams and her homework is always of a high standard. I am confident that she will continue to make good progress in History.",
    "",
  ],
  [
    "James",
    "Clerk Maxwell",
    "he",
    9,
    8,
    2,
    3,
    "magnetic personality",
    3,
    "James has made great progress in History this year. He has been a top performer in the class, with excellent attendance and homework. However, he has struggled to participate in the classroom, often getting frustrated or overwhelmed easily. This has been reflected in his exam results, where he achieved a grade of 3. James is a very magnetic personality and has the potential to be an excellent historian. With a little more focus and attention, he could easily achieve even higher grades.",
    "",
  ],
  // ["Wilhelm","Conrad Roentgen","he",7,7,4,5,"very bright and talented",5,"Wilhelm has made excellent progress in History this year. He has shown great effort in the classroom and has achieved good grades in his exams. Wilhelm is a very bright and talented student who has great potential in this subject.","Wilhelm has made great progress in History this year. He has shown himself to be a very bright and talented student, and has consistently achieved good grades. He has a very good class attendance record, and is always willing to put in the effort to understand new concepts. His homework is always of a high standard, and he has achieved an exam grade of 5 in History. There is room for improvement, but Wilhelm is a very capable student who is sure to achieve success in the future."  ],
  [
    "Marie",
    "Curie",
    "she",
    6,
    7,
    2,
    6,
    "fearless in pursuit of learning",
    6,
    "Marie has made great progress in History this year. She has been a very good class attendee, with great effort. However, her participation in the classroom has been poor, as she struggles to focus and pay attention when learning. This often leads to frustration or feeling overwhelmed. Marie's homework has been good, and she achieved an exam grade of 6, which she is pleased with. Overall, Marie is a fearless learner who is making great strides in her pursuit of knowledge.",
    "",
  ],
  // ["Louis","Daguerre","he",6,3,4,8,"dresses smart for class",8,"Louis has made good progress in History this year. He is able to focus and concentrate when learning, and is willing to put in the effort to understand new concepts. His homework is usually of a good standard. However, Louis' class attendance could be improved. He has also achieved a great grade in his History exams.","Louis has made good progress in History this year. His performance at school has been good, with some areas for improvement. Louis has achieved some great grades in History, including an exam grade of 8. Louis' attendance in class could be better. He needs to improve in this area in order to make the most of his learning. Louis is a sound participant in the classroom. He is able to focus and concentrate when learning, and is willing to put in the effort to understand new concepts. Louis' homework is generally good. He is doing well in this area. Louis achieved an exam grade of 8 in History, which is great. He dresses smartly for class and is generally a credit to the class. Overall, Louis is doing well in History. With some improvements in attendance and participation, he will be able to make even more progress in the future."  ],
  [
    "John",
    "Dalton",
    "he",
    2,
    8,
    8,
    9,
    "always helping others",
    9,
    "John is a History student who has made excellent progress this year. He has performed brilliantly at school, with excellent class attendance and participation. His only area for improvement is his homework, which is not up to standard. However, John achieved an outstanding grade of 9 in his History exam, demonstrating his excellent knowledge and understanding of the subject. John is always helping others and is a valuable member of the class. He is a bright student with a love for learning, and is always looking for new opportunities to improve his knowledge. John is an excellent History student who has made great progress this year.",
    "",
  ],
  // ["Charles","Darwin","he",6,8,7,1,"a big character with many friends in the classroom",1,"Charles has made great progress in History this year. He has performed well at school, with excellent class attendance and participation. His homework has been good, and he achieved an exam grade of 1. Charles is a big character with many friends in the classroom. He is a great example to others and is always willing to help others learn. He is very concerned about his exam results, but is doing well.Charles is a great student who has made excellent progress in History this year. He has worked hard in class, with excellent class attendance and participation. His homework has been good, and he achieved an exam grade of 1. Charles is a big character with many friends in the classroom. He is a great example to others and is always willing to help others learn. He is very concerned about his exam results, but is doing well.",""  ],
  [
    "Amelia",
    "Earhart",
    "she",
    4,
    7,
    9,
    2,
    "a free spirit",
    2,
    "Amelia has made good progress in History this year. She has been a model student in class, always participating and asking questions. Her attendance has been exemplary and she has always hand in her homework on time. However, Amelia has been concerned about her examination results. She achieved a grade of 2 in her most recent History exam. Amelia is a free spirit and she loves learning. She is always looking for new opportunities to improve her knowledge. I am confident that Amelia will continue to make good progress in History.",
    "",
  ],
  [
    "Thomas",
    "Edison",
    "he",
    7,
    7,
    8,
    3,
    "an electrifying personality",
    3,
    "Thomas has made great progress in History this year. His class attendance is very good and he always puts great effort into his work. Thomas loves learning and is always looking for new opportunities to improve his knowledge. His homework is very good and he is always willing to help other students in the class. Thomas achieved an exam grade of 3 in History, but he is a little concerned about his exam results. Thomas has an electrifying personality and is always enthusiastic about learning.",
    "",
  ],
  [
    "Albert",
    "Einstein",
    "he",
    6,
    4,
    9,
    2,
    "extremely gifted individual",
    2,
    "Albert is a very gifted individual who has made excellent progress in their History studies this year. They have been a sound learner, with good class attendance and a focus on their studies. They have been an exceptional participant in the classroom, always looking for new opportunities to improve their knowledge. Albert has also done well with their homework, achieving good grades. They are however, concerned about their exam results after only achieving a grade 2. Albert is an extremely gifted individual who has the potential to achieve great things. With a little more focus on their studies, they are sure to achieve even higher grades in their exams.",
    "",
  ],
  [
    "Leonhard",
    "Euler",
    "he",
    1,
    6,
    9,
    1,
    "",
    1,
    "Leonhard has made good progress in History this year. He has been a top performer in the class, always looking for new opportunities to improve their knowledge. However, he has been very poor with his homework and this has resulted in him getting a 1 in his History exam. Leonhard is a very smart student and has great potential. With a little more effort, he will be able to achieve even more.",
    "",
  ],
  [
    "Michael",
    "Faraday",
    "he",
    5,
    3,
    5,
    6,
    "splits opinions",
    6,
    "Michael has made good progress in History this year. He has performed well in class and is always asking questions or seeking clarification when needed. However, he has missed a lot of classes and his homework is only okay. Michael achieved an exam grade of 6 in History, which is pleasing. Michael is a very enthusiastic learner and is always keen to improve.",
    "",
  ],
  [
    "Enrico",
    "Fermi",
    "he",
    1,
    6,
    9,
    1,
    "full of energy",
    1,
    "Enrico has made good progress in History this year. He is a bright and enthusiastic student who loves learning and is always looking for new opportunities to improve their knowledge. However, Enrico's homework is very poor and he is very concerned about his exam results. Enrico is a bright and enthusiastic student who loves learning and is always looking for new opportunities to improve their knowledge. However, Enrico's homework is very poor and he is very concerned about his exam results.",
    "",
  ],
  [
    "Alexander",
    "Fleming",
    "he",
    9,
    7,
    7,
    2,
    "dutiful in his studies",
    2,
    "Alexander has made great progress in History this year. He has worked hard in class and has been a great example to others. His homework has been super and he has achieved an exam grade of 2. He is concerned about his exam results but is dutiful in his studies. He is a great asset to the class and is always willing to help others learn.",
    "",
  ],
  [
    "Henry",
    "Ford",
    "he",
    1,
    8,
    9,
    6,
    "",
    6,
    "Henry has made excellent progress in History this year. He has been a top performer in the class, always looking for new opportunities to improve his knowledge. He has also been very active in the classroom, participating in discussions and always eager to learn more. However, his homework has been very poor and he has not been able to keep up with the required readings. Nevertheless, he has achieved an exam grade of 6 in History, which is a pleasing academic achievement. We are confident that with more effort, Henry will be able to improve his homework habits and continue to excel in History.",
    "",
  ],
  [
    "Sigmund",
    "Freud",
    "he",
    1,
    8,
    5,
    5,
    "",
    5,
    "Sigmund is a student in History at school. He has made progress in History this year and is doing well in the class. He has a good attendance record and is always participating in the class. However, his homework is very poor and he needs to improve in this area. He has achieved an exam grade of 5 in History, which is good, but there is room for improvement.",
    "",
  ],
  [
    "Elizabeth",
    "Fry",
    "she",
    1,
    5,
    8,
    9,
    "",
    9,
    "Elizabeth has made excellent progress in History this year. She has performed well in school and has shown a great interest in the subject. Elizabeth has been very enthusiastic in class and has participated actively in discussions. However, her homework has been very poor. Elizabeth has achieved an outstanding grade of 9 in her History exams. Elizabeth is a bright and intelligent student who loves learning. She is always looking for new opportunities to improve her knowledge. Elizabeth has the potential to be an excellent History student.",
    "",
  ],
  [
    "Galileo",
    "Galilei",
    "he",
    3,
    7,
    9,
    3,
    "humble",
    3,
    "Galileo has made great progress in History this year. He has been a top performer in the class and has shown a real love for learning. His attendance has been very good and his effort great. He has been an exceptional participant in the classroom, always looking for new opportunities to improve his knowledge. His homework has been infrequent but he has achieved an exam grade of 3. Galileo is a humble student who is always looking to improve. He is a top performer in the class and has shown a real love for learning. His attendance has been very good and his effort great. He has been an exceptional participant in the classroom, always looking for new opportunities to improve his knowledge. His homework has been infrequent but he has achieved an exam grade of 3. Galileo is a humble student who is always looking to improve.",
    "",
  ],
  // ["Alexander","Graham Bell","he",4,5,6,8,"bright",8,"Alexander has made good progress in History this year. He is a bright student who is motivated to learn and is always looking for new opportunities to improve their knowledge. He has achieved an exam grade of 8 in History, great grades.",""  ],
  [
    "Werner",
    "Heisenberg",
    "he",
    9,
    5,
    9,
    5,
    "fundamentally understands the subject matter",
    5,
    "Werner has made great progress in History this year. He is a top performer in the class and is always looking for new opportunities to improve his knowledge. His homework is always super and he achieved an exam grade of 5 in History. However, there is always room for improvement and he should continue to work hard in order to achieve the best possible results.",
    "",
  ],
  [
    "Grace",
    "Hopper",
    "she",
    5,
    6,
    6,
    5,
    "",
    5,
    "Grace has made good progress in History this year. She has performed well at school, with good class attendance and participation. Her homework is acceptable, and she achieved an exam grade of 5. There is room for improvement, but Grace is motivated to learn and is always looking for new opportunities to improve her knowledge. Grace is a good student who is doing well in History.",
    "",
  ],
  [
    "Thomas",
    "Jefferson",
    "he",
    9,
    8,
    8,
    8,
    "",
    8,
    "Thomas has made excellent progress in History this year. He has been a model student, with great attendance and participation in class. His homework has been of an exceptionally high standard and he has achieved great grades in his exams. Thomas is a credit to himself and his family. He loves learning and is always looking for new opportunities to improve his knowledge. We are confident that Thomas will continue to make excellent progress in History and we are very proud of his achievements to date.",
    "",
  ],
  [
    "Frida",
    "Kahlo",
    "she",
    9,
    6,
    5,
    6,
    "an inspiration to us all",
    6,
    "Frida has made great progress in her study of History this year. She has demonstrated excellent class attendance and her participation in class is always enthusiastic and eager. Her homework is always of a very high standard and she achieved an exam grade of 6 in History, which is an excellent academic achievement. Frida is an inspiration to us all and I am sure she will continue to make great progress in her studies next year.",
    "",
  ],
  [
    "Johannes",
    "Kepler",
    "he",
    1,
    5,
    5,
    3,
    "going through a hard time",
    3,
    "Johannes has been studying History at school this year and has made good progress. He has performed well in class and is always asking questions or seeking clarification when needed. However, his homework has been very poor. He achieved an exam grade of 3 in History, which is a little concerned. Going through a hard time, Johannes has made good progress in History this year. He is always asking questions or seeking clarification when needed. However, his homework has been very poor. He achieved an exam grade of 3 in History, which is a little concerned.",
    "",
  ],
  [
    "Joseph",
    "Lister",
    "he",
    2,
    6,
    8,
    5,
    "lazy",
    5,
    "Joseph is a good student who is interested in their studies. With a little more focus on their homework, they will be able to achieve even better grades. Joseph has made good progress in History this year. He has performed well in school and is always looking for new opportunities to improve his knowledge. However, his homework is not up to standard and he could do better in this area. Overall, Joseph is a good student who is interested in his studies. With a little more focus on his homework, he will be able to achieve even better grades.",
    "",
  ],
  [
    "Ada",
    "Lovelace",
    "she",
    6,
    3,
    6,
    9,
    "",
    9,
    "Ada has made excellent progress in History this year. She is a motivated learner who is always looking for new opportunities to improve their knowledge. Ada's infrequent class attendance is a area for improvement. However, when she is in the classroom she is a good participant. Ada's homework is always of a good standard. Ada achieved an exam grade of 9 in History, which is outstanding. In conclusion, Ada is a high-achieving student who has a real passion for History. She has made excellent progress this year and is always looking for ways to improve her knowledge. With Ada's high level of motivation and dedication, she is sure to continue achieving great things in History.",
    "",
  ],
  // ["Isaac","Newton","he",7,8,3,6,"talented",6,"Isaac has made good progress in History this year. He has worked hard and achieved an exam grade of 6. He is a talented student who has a bright future ahead of him.","Isaac has made good progress in History this year. He has been a model student in class, always paying attention and participating in discussions. His homework has been excellent, and he has achieved an exam grade of 6. Isaac is a talented student with a bright future ahead of him."  ],
  [
    "Florence",
    "Nightingale",
    "she",
    7,
    6,
    5,
    8,
    "very clean",
    8,
    "Florence has made excellent progress in History this year. She is a bright and enthusiastic learner, who is always keen to ask questions and clarifications when needed. Her homework is always of a very high standard and she is regularly achieving top marks in class tests and exams. Florence is a very hard-working student who always puts 100% effort into everything she does. It is clear that she has a real passion for History and she is always keen to learn more about different historical periods and events. Keep up the good work, Florence!",
    "",
  ],
  [
    "Rosa",
    "Parks",
    "she",
    9,
    8,
    7,
    1,
    "kind",
    5,
    "Rosa has made excellent progress in History this year. She has performed well in school, with great attendance and participation. Her homework has been super, and she achieved an exam grade of 1. She is a great example to others and is always willing to help others learn. Rosa is very concerned about her exam results and is kind.",
    "Rosa has made excellent progress in History this year. She has performed brilliantly at school, and her attendance is excellent. Rosa is a great example to others in the classroom, and is always willing to help others learn. Her homework is super, and she achieved an exam grade of 1 in History. Rosa is a kind and caring person, and is always willing to help others. She is a great example to others in the classroom, and is always willing to help others learn.",
  ],
  [
    "Louis",
    "Pasteur",
    "he",
    3,
    4,
    7,
    8,
    "mature",
    8,
    "Louis has made great progress in History this year. He is a sound student who attends class regularly and needs to focus in order to do his best. Louis is a very good participant in the classroom; he is a great example to others and is always willing to help others learn. Louis's homework is infrequent but he has the potential to do much better. Louis achieved an exam grade of 8 in History, which is great. He is a mature student who is capable of great things.",
    "",
  ],
  // ["Max","Planck","he",8,6,9,7,"takes things to the extreme sometimes",7,"Max has made excellent progress in History this year. He is a high-achieving student who always looks for new opportunities to improve their knowledge. Max's excellent homework habits have helped him to achieve an exam grade of 1 in History. However, Max sometimes takes things to the extreme and this can be a concern. Overall, Max is a fantastic student who is always keen to participate in the classroom and learn new things. Keep up the good work, Max!","Max has made excellent progress in History this year. He is a high-achieving student who loves learning and is always looking for new opportunities to improve their knowledge. Max has excellent class attendance and participates exceptional in the classroom. His homework is always of a high standard and he achieved an exam grade of 7 in History. Max should be commended for their academic achievement. However, Max takes things to the extreme sometimes and this can be detrimental to his learning. Overall, Max is a high-achieving student who is passionate about learning. He has made great progress this year and is on track to achieve even more in the future."  ],
  [
    "Eleanor",
    "Rathbone",
    "she",
    2,
    6,
    4,
    5,
    "developing bad habits",
    5,
    "Eleanor has made good progress in History this year. She is a sound participant in the classroom and is able to focus and concentrate when learning. However, her homework is not up to standard and she has developed bad habits. Eleanor achieved an exam grade of 5 in History, which is room for improvement. In conclusion, Eleanor is a hard-working student who is willing to put in the effort to understand new concepts. However, she needs to improve her homework habits in order to reach her full potential.",
    "",
  ],
  [
    "Ernest",
    "Rutherford",
    "he",
    8,
    3,
    3,
    4,
    "disruptive",
    4,
    "Ernest has made good progress in History this year. He has performed well at school and achieved excellent grades in his exams. However, he has been disruptive in class and has often found excuses not to engage in learning. This has led to his poor attendance in class. Ernest needs to improve his attendance and participation in order to achieve his full potential in History.",
    "",
  ],
  // ["Johann","Sebastian Bach","he",5,6,8,7,"can be disruptive",7,"Johann is a great kid who is always looking to improve their knowledge. They are a hard worker who takes their education seriously. With a few more years of practice, Johann will be an excellent historian.","Johann has made good progress in History this year. He has been a good class member, with excellent attendance and participation. His homework has been acceptable, and he has achieved a grade 7 in History. There are areas where Johann can improve. He can be disruptive at times, which can interfere with his own learning and that of others. However, he is always looking for new opportunities to learn, and his love of learning is evident. Overall, Johann is doing well in History. With some continued effort, he can make even more progress and achieve even higher grades."  ],
  // ["William","Shakespeare","he",4,5,5,5,"imaginative",5,"William has made good progress in History this year and with continued effort he will continue to improve.","William has made good progress in History this year and is performing well in the classroom. His homework is sound and he has achieved a grade of 5 in his exams. William is an imaginative thinker and is always asking questions or seeking clarification when needed. He is a keen learner and is always eager to learn more. William has made good progress in History this year and is performing well in the classroom. His homework is sound and he has achieved a grade of 5 in his exams. William is an imaginative thinker and is always asking questions or seeking clarification when needed. He is a keen learner and is always eager to learn more."  ],
  [
    "Mary",
    "Shelley",
    "she",
    1,
    8,
    4,
    7,
    "kind and sweet",
    7,
    "Mary has made excellent progress in History this year. Her class attendance is brilliant and she is able to focus and concentrate when learning. However, her homework is very poor. Mary achieved an exam grade of 7 in History. She should be commended for their academic achievement. Mary is a kind and sweet student who is willing to put in the effort to understand new concepts.",
    "",
  ],
  [
    "Virginia",
    "Woolf",
    "she",
    2,
    7,
    5,
    2,
    "quiet",
    2,
    "Virginia has made good progress in History this year. She has been a very good student, with great attendance and effort. Virginia has been an acceptable participant in the classroom, always asking questions or seeking clarification when needed. However, her homework has been poor and not up to standard. Virginia is concerned about her exam results, as she only achieved a grade of 2. Virginia is a quiet student. Despite some areas of improvement, Virginia has made good progress in History this year. She is always eager to learn and is a great asset to the class. Virginia's attendance and effort have been very good, and she is always willing to participate in class. However, her homework needs to improve in order to meet the standards of the class. Virginia is concerned about her exam results, but she is still working hard and progressing well in History.",
    "",
  ],
  [
    "Orville",
    "Wright",
    "he",
    7,
    6,
    5,
    8,
    "disruptive",
    8,
    "Orville has made excellent progress in History this year. He is a top student in the class and is always asking questions or seeking clarification when needed. Additionally, Orville has very good class attendance and homework. However, there are areas for improvement. For example, Orville can be disruptive in class and this affects his learning. Despite this, Orville is a bright student with great potential. With continued effort, Orville will no doubt achieve great things in History.",
    "",
  ],
];

type Student = {
  id: string;
  name: string;
  surname: string;
  homework: number;
  attendance: number;
  participation: number;
  grade: number;
  comment: string;
  report: string;
};

const students: Student[] = studentData.map((student, index) => ({
  id: ID(),
  name: student[0],
  surname: student[1],
  homework: student[3],
  attendance: student[4],
  participation: student[5],
  grade: student[6],
  comment: student[7],
  report: student[9],
}));

const columns: Column<Student>[] = [
  { key: "name", label: "Name", flex: 4 },
  { key: "surname", label: "Surname", flex: 3 },
  { key: "homework", label: "Homework", flex: 1 },
  { key: "attendance", label: "Attendance", flex: 1 },
  { key: "participation", label: "Participation", flex: 1 },
  { key: "grade", label: "Grade", flex: 1 },
  { key: "comment", label: "Comment", flex: 3 },
];

const INTERVAL = 5000;

const ExampleReports: FC = () => {
  const [rowIndex, setRowIndex] = useState(0);

  const handleNext = useCallback(() => {
    setRowIndex((rowIndex) => (rowIndex + 1) % students.length);
  }, []);

  const handlePrevious = useCallback(() => {
    setRowIndex(
      (rowIndex) => (rowIndex - 1 + students.length) % students.length
    );
  }, []);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrevious,
    trackMouse: true,
  });

  useEffect(() => {
    const interval = setInterval(handleNext, INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const student = students[rowIndex];

  return (
    <Box column gap="1rem" width="100%" overflowHidden>
      <Box row minWidth="20rem" maxWidth="30rem" margin="0 auto">
        <div {...swipeHandlers} style={{ width: "100%", userSelect: "none" }}>
          <Carousel activeIndex={rowIndex}>
            {students.map((student) => (
              <Box key={student.id} minWidth="20rem" maxWidth="30rem">
                <Card>
                  <Heading3>
                    {student.name} <Small>{student.surname}</Small>
                  </Heading3>
                  <Heading4>
                    <DateString date={now} />{" "}
                    <Small>
                      <TimeString date={now} />
                    </Small>
                  </Heading4>
                  <Paragraph>{student.report}</Paragraph>
                  <Paragraph>
                    {student.report && (
                      <Small>
                        {student.report.split(" ").length} words,{" "}
                        {student.report.length} characters
                      </Small>
                    )}
                  </Paragraph>
                </Card>
              </Box>
            ))}
          </Carousel>
        </div>
      </Box>
      <Box row gap="1rem" width="100%">
        <Spreadsheet data={students} columns={columns} activeId={student.id} />
      </Box>
    </Box>
  );
};

export default ExampleReports;
