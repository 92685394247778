import styled from "@emotion/styled";
import { FC, ReactNode } from "react";
import Box from "./Box";

const BaseFooter = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #eee;
  padding: 4rem;
`;

type FooterProps = {
  children: ReactNode;
};

const Footer: FC<FooterProps> = (props) => {
  return (
    <BaseFooter>
      <Box row maxWidth="40rem" justifyContent="space-around" flex={1}>
        {props.children}
      </Box>
    </BaseFooter>
  );
};

export { Footer };
