import styled from "@emotion/styled";
import Box from "./Box";

const BaseTextArea = styled.textarea`
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #4f4f4f;
  background-color: #ffffee;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 100%;
  height: 4rem;
  resize: vertical;
`;

const TextAreaLabel = styled.label`
  color: #4f4f4f;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0.5rem;
  display: block;
`;

type TextAreaProps = {
  label: string;
  value: string;
  onChange: (value: string) => void;
};

const TextArea = (props: TextAreaProps) => {
  const id = props.label.replace(" ", "-").toLowerCase();
  return (
    <Box column width="100%" flex={1}>
      <TextAreaLabel htmlFor={id}>{props.label}</TextAreaLabel>
      <BaseTextArea
        id={id}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        spellCheck
      />
    </Box>
  );
};

export default TextArea;
