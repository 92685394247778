import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Box, Steps, NavItem } from "../components";

const WorkflowsView: FC = () => {
  return (
    <>
      <Box
        column
        width="100%"
        maxWidth="40rem"
        margin="0 auto"
        padding="1rem"
        gap="1rem"
      >
        <Steps>
          <NavItem to="/workflows/subject">Subject</NavItem>
          <NavItem to="/workflows/generate">Generate</NavItem>
          <NavItem to="/workflows/reports">Reports</NavItem>
        </Steps>
      </Box>
      <Outlet />
    </>
  );
};

export default WorkflowsView;
