import { FC, useEffect } from "react";
import { Outlet, Routes, useLocation, useRoutes } from "react-router-dom";
import {
  RootView,
  ContextView,
  SpreadsheetView,
  GenerateView,
  ReportsView,
  WorkflowsView,
  PricingView,
  AccountView,
  NotFound404View,
  ContactSupportView,
  PrivacyPolicyView,
  StudentSetsView,
  BlogIndexView,
  HowMuchTimeDoTeachersSpendOnReportsBlogPostView,
  TheProblemWithReportCommentBanksBlogPostView,
  AreSchoolReportsWorthItBlogPostView,
} from "./views";
import { useStore } from "./hooks";
import Wrapper from "./Wrapper";

const App: FC = () => {
  const {
    studentSets,
    studentSet,

    context,

    students,
    student,

    reports,

    onSubjectChange,
    onSetNameChange,
    onGradeSystemChange,

    onNameChange,
    onSurnameChange,
    onPronounChange,
    onHomeworkChange,
    onParticipationChange,
    onAttendanceChange,
    onGradeChange,
    onGradeSentimentChange,
    onCommentChange,

    onSelectStudentSet,
    onSelectStudent,

    onAddStudentSet,
    onAddStudent,
    onAddReport,
    onImportStudents,

    currency,
    onCurrencyChange,
  } = useStore();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const routes = useRoutes([
    {
      path: "/",
      element: <Wrapper />,
      children: [
        { path: "/", element: <RootView /> },
        { path: "/pricing", element: <PricingView
          currency={currency}
          onCurrencyChange={onCurrencyChange}
        /> },
        { path: "/contact", element: <ContactSupportView /> },
        { path: "/privacy", element: <PrivacyPolicyView /> },
        { path: "/account", element: <AccountView /> },
        {
          path: "/sets",
          element: (
            <StudentSetsView
              studentSets={studentSets}
              onAddStudentSet={onAddStudentSet}
              onSelectStudentSet={onSelectStudentSet}
            />
          ),
        },
        {
          path: "/import",
          element: (
            <SpreadsheetView
              students={students}
              onImportStudents={onImportStudents}
              context={context}
            />
          ),
        },
        {
          path: "/workflows",
          element: <WorkflowsView />,
          children: [
            {
              path: "subject",
              element: (
                <ContextView
                  context={context}
                  onSubjectChange={onSubjectChange}
                  onSetNameChange={onSetNameChange}
                  onGradeSystemChange={onGradeSystemChange}
                />
              ),
            },
            {
              path: "generate",
              element: (
                <GenerateView
                  studentSets={studentSets}
                  studentSet={studentSet}
                  context={context}
                  students={students}
                  onSelectStudent={onSelectStudent}
                  onAddStudent={onAddStudent}
                  student={student}
                  onAddReport={onAddReport}
                  onNameChange={onNameChange}
                  onSurnameChange={onSurnameChange}
                  onPronounChange={onPronounChange}
                  onHomeworkChange={onHomeworkChange}
                  onParticipationChange={onParticipationChange}
                  onAttendanceChange={onAttendanceChange}
                  onGradeChange={onGradeChange}
                  onGradeSentimentChange={onGradeSentimentChange}
                  onCommentChange={onCommentChange}
                />
              ),
            },
            {
              path: "reports",
              element: (
                <ReportsView
                  studentSet={studentSet}
                  student={student}
                  students={students}
                  onSelectStudent={onSelectStudent}
                  onAddStudent={onAddStudent}
                  reports={reports}
                />
              ),
            },
          ],
        },
        {
          path: "/blog",
          element: <Outlet />,
          children: [
            { path: "", element: <BlogIndexView /> },
            {
              path: "are-school-reports-worth-it",
              element: <AreSchoolReportsWorthItBlogPostView />,
            },
            {
              path: "the-problem-with-report-comment-banks",
              element: <TheProblemWithReportCommentBanksBlogPostView />,
            },
            {
              path: "how-much-time-do-teachers-spend-on-reports",
              element: <HowMuchTimeDoTeachersSpendOnReportsBlogPostView />,
            },
          ],
        },
        { path: "*", element: <NotFound404View /> },
      ],
    },
  ]);

  return routes;
};

export default App;
