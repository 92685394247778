import { hydrateRoot } from "react-dom/client";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { Auth } from "@aws-amplify/auth";
import { API } from "@aws-amplify/api";
import {
  AWS_USER_POOL_WEB_CLIENT_ID,
  AUTH_DOMAIN,
  AWS_REGION,
  API_NAME,
  API_ENDPOINT,
  AWS_USER_POOL_ID,
} from "./constants";
import { FC } from "react";
import { BrowserRouter, Routes } from "react-router-dom";
import App from "./App";
import { withAuthorizer } from "./components";

Sentry.init({
  dsn: "https://1cd5576c60ba481e9a6474f65e78bc9e@o4503910377390080.ingest.sentry.io/4503910378635264",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

API.configure({
  endpoints: [
    {
      name: API_NAME,
      endpoint: API_ENDPOINT,
      region: AWS_REGION,
    },
  ],
});

Auth.configure({
  region: AWS_REGION,
  userPoolId: AWS_USER_POOL_ID,
  userPoolWebClientId: AWS_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: AUTH_DOMAIN,
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
    responseType: "token",
  },
});

const RoutedApp: FC = withAuthorizer(() => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
));

const container = document.getElementById("app") as HTMLElement;
const root = hydrateRoot(container, <RoutedApp />);
