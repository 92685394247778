// a component that displays an error message if the error prop is not null
// it wraps the children in a div with a red border and displays the error message
// if the error prop is not null
//
// if the error prop is null, it just renders the children

import { FC, ReactNode } from "react";
import styled from "@emotion/styled";
import { Span } from "./Typography";
import Box from "./Box";

type ErrorLabelProps = {
  error: ReactNode | null;
  children: ReactNode;
};

const Tooltip = styled(Span)`
  display: block;
  padding: 0.5rem;
  margin: 0.5rem 0;
  background-color: #fff;
  border: 1px dashed red;
  &:before {
    content: "⚠️";
    margin-right: 0.5rem;
  }
`;

const ErrorLabel: FC<ErrorLabelProps> = (props) => {
  return (
    <Box column flex={1}>
      <Box row reverse>
        {props.children}
      </Box>
      {props.error && (
        <Box row reverse>
          <Tooltip>{props.error}</Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default ErrorLabel;
