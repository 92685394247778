import { useCallback } from "react";
import { usePost } from "./useRestApi";

const usePostGenerate = () => {
  const { data, loading, error, post } = usePost<string, string>({
    url: "/generate",
  });

  const generate = useCallback(
    async (prompt: string) => {
      const request = btoa(prompt);
      const response = await post(request);
      const text = atob(response);
      return text;
    },
    [post]
  );

  return { data, loading, error, generate };
};

export default usePostGenerate;
