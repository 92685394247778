import { useCallback } from "react";

const useWriteToClipboard = () => {
  // make SSR safe
  if (typeof navigator === "undefined") {
    return {
      writeToClipboard: () => {},
    };
  }

  const writeToClipboard = useCallback(
    (text: string) => {
      navigator.clipboard.writeText(text);
    },
    [navigator?.clipboard]
  );

  return { writeToClipboard };
};

export default useWriteToClipboard;
