import { FC, ReactNode } from "react";
import styled from "@emotion/styled";

const BaseMain = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

type MainProps = {
  children: ReactNode;
};

const Main: FC<MainProps> = (props) => {
  return <BaseMain>{props.children}</BaseMain>;
};

export { Main };
