import styled from "@emotion/styled";
import { FC, ReactNode } from "react";
import { BaseButton, ButtonProps } from "./Button";

const BaseButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    box-shadow: 1px 1px 0.5rem #ddd;
    border-radius: 0.5rem;
`;

const BaseButtonGroupItem = styled(BaseButton)`
    flex: 1;
    border-radius: 0;
    border: ${(props) => (props.secondary ? "1px solid #ccc" : "1px solid #0074D9")};
    border-left: none;
    box-shadow: none;

    &:first-of-type {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border: ${(props) => (props.secondary ? "1px solid #ccc" : "1px solid #0074D9")};
    }

    &:last-of-type {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }
`;

const ButtonGroupItem: FC<ButtonProps> = (props) => {
    return (
        <BaseButtonGroupItem secondary={props.secondary} onClick={props.onClick} disabled={props.disabled}>
            {props.children}
        </BaseButtonGroupItem>
    )
}

const ButtonGroup: FC<{children: ReactNode}> = (props) => {
    return (
        <BaseButtonGroup>
            {props.children}
        </BaseButtonGroup>
    )
}

export { ButtonGroup, ButtonGroupItem };
