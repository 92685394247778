import { FC, useCallback, useEffect, useMemo } from "react";
import { GradeSystem, StudentSet } from "../types";
import {
  Heading1,
  Heading2,
  Paragraph,
  Card,
  Box,
  Button,
} from "../components";
import { useNavigate } from "react-router-dom";

export type StudentSetsViewProps = {
  studentSets: StudentSet[];
  onAddStudentSet: () => void;
  onSelectStudentSet: (id: string) => void;
};

const StudentSetsView: FC<StudentSetsViewProps> = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Reports Writer | Student Sets";
  }, []);

  const handleImportClick = useCallback(
    (set: StudentSet) => {
      props.onSelectStudentSet(set.id);
      navigate("/import");
    },
    [navigate]
  );

  const handleWriteClick = useCallback(
    (set: StudentSet) => {
      props.onSelectStudentSet(set.id);
      if (set.context.name && set.context.subject) {
        navigate("/workflows/generate");
      } else {
        navigate("/workflows/subject");
      }
    },
    [navigate]
  );

  const handleAddClick = useCallback(() => {
    props.onAddStudentSet();
    navigate("/workflows/subject");
  }, [navigate]);

  return (
    <Box column padding="1rem" gap="2rem">
      <Box column maxWidth="40rem" margin="0 auto" gap="1rem" padding="1rem">
        <Heading1>Student Sets</Heading1>
        <Paragraph>
          Student sets are groups of students that you can use to generate
          reports.
        </Paragraph>
      </Box>
      <Box row gap="1rem" flex={1} wrap>
        {props.studentSets.map((set, index) => (
          <Box key={set.id} column gap="1rem" flex={1} maxWidth="20rem">
            <Card>
              <Heading2>{set.context.name || `Class ${index + 1}`}</Heading2>
              <Paragraph>{set.context.subject || "No subject"}</Paragraph>
              <Paragraph>{set.students.length} students</Paragraph>
              <Paragraph>
                {set.context.gradeSystem || GradeSystem.UK} grade system
              </Paragraph>
              <Box row gap="1rem" reverse>
                <Button onClick={() => handleWriteClick(set)}>
                  Write Reports
                </Button>
                <Button secondary onClick={() => handleImportClick(set)}>
                  Import Students
                </Button>
              </Box>
            </Card>
          </Box>
        ))}
        {props.studentSets.length === 0 && (
          <Box column gap="1rem" flex={1} center>
            <Paragraph>You have no student sets.</Paragraph>
          </Box>
        )}
      </Box>
      <Box row justifyContent="center">
        <Button onClick={handleAddClick}>Add Student Set</Button>
      </Box>
    </Box>
  );
};

export default StudentSetsView;
