import { useCallback, useState } from "react";
import API from "@aws-amplify/api";
import { API_NAME } from "../constants";
import { useSession } from "../components";

const useGet = <T>(args: { url: string }) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const session = useSession();

  const get = useCallback(async () => {
    try {
      setLoading(true);
      const response = await API.get(API_NAME, args.url, {
        headers: {
          Authorization: session.token,
        },
      });
      setData(response);
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  }, [args.url]);

  return { data, loading, error, get };
};

const usePost = <A, B>(args: { url: string }) => {
  const [data, setData] = useState<B | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const session = useSession();

  const post = useCallback(
    async (body: A): Promise<B> => {
      let response: B;
      try {
        setLoading(true);
        response = await API.post(API_NAME, args.url, {
          body,
          headers: {
            Authorization: session.token,
          },
        });
        setData(response);
        setLoading(false);
        setError(null);
        return response;
      } catch (e) {
        setError(e as Error);
        setLoading(false);
        throw e;
      }
    },
    [args.url, session.token]
  );

  return { data, loading, error, post };
};

export { useGet, usePost };
