import { FC, ReactNode, useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Span,
  Strong,
  Image,
  Button,
  Heading1,
  Paragraph,
  Heading3,
  useSession,
  ErrorLabel,
  Heading2,
  ButtonGroup,
  ButtonGroupItem,
} from "../components";
import credits100 from "../credits-100.webp";
import credits300 from "../credits-300.webp";
import credits1000 from "../credits-1000.webp";
import { SIGNUP_URL } from "../constants";
import { usePostPaymentLinks } from "../hooks";
import { Currency, ProductID } from "../types";
import { Link } from "react-router-dom";

// three columns with equal width
// each is a bundle of credits for sale
// 100 for £5
// 300 for £10
// 1000 for £20

type BundleColumnProps = {
  credits: number;
  price: number;
  children: ReactNode;
  productID: ProductID;
  currency: Currency;
};

const exchangeRates = {
  GBP: 5,
  USD: 6,
  AUD: 9,
};


const BundleColumn: FC<BundleColumnProps> = (props) => {
  const { loading, error, post } = usePostPaymentLinks();

  const session = useSession();

  const handleClick = useCallback(async () => {
    const url = await post({ productID: props.productID, currency: props.currency });
    window.location.href = url;
  }, [post, props.productID, props.currency]);

  const currencySymbol = useMemo(() => {
    switch (props.currency) {
      case "GBP":
        return "£";
      case "USD":
        return "$";
      case "AUD":
        return "A$";
    }
  }, [props.currency]);

  const price = useMemo(() => (
    (props.price / exchangeRates.GBP) * exchangeRates[props.currency]
  ), [props.price, props.currency]);

  const priceString = useMemo(() => (
    `${currencySymbol} ${price.toFixed(2)}`
  ), [currencySymbol, price]);

  const pricePerCredit = useMemo(() => {
    const pricePerCredit = price / props.credits;
    return `${currencySymbol} ${pricePerCredit.toFixed(2)}`;
  }, [currencySymbol, price, props.credits]);

  return (
    <Box
      column
      center
      gap="1rem"
      flex={1}
      padding="1rem"
      maxWidth="20rem"
      minWidth="10rem"
    >
      {props.children}
      <Box row center gap="0.5rem">
        <Heading2>{props.credits}</Heading2>
        <Span>credits</Span>
      </Box>
      <Box row center gap="0.5rem">
        <Heading3>{priceString}</Heading3>
      </Box>
      <Box row center gap="0.5rem">
        <Strong>{pricePerCredit}</Strong>
        <Span>per credit</Span>
      </Box>
      {session.loggedIn && (
        <ErrorLabel error={error ? error.message : null}>
          <Button onClick={handleClick}>
            {loading ? "Loading..." : "Buy"}
          </Button>
        </ErrorLabel>
      )}
    </Box>
  );
};

type PricingViewProps = {
  currency: Currency;
  onCurrencyChange: (currency: Currency) => void;
};

const PricingView: FC<PricingViewProps> = (props) => {
  const session = useSession();

  useEffect(() => {
    document.title = "Reports Writer | Pricing";
  }, []);

  return (
    <Box column center gap="1rem" padding="1rem">
      <Box
        column
        center
        gap="1rem"
        padding="1rem"
        maxWidth="40rem"
        margin="0 auto"
      >
        <Heading1>Pricing</Heading1>
        <Paragraph>
          Looking for a way to generate school reports quickly and easily? Look
          no further than our credit bundles! With our credits, teachers can
          easily and quickly create school reports. Each credit can be redeemed
          for one report.
        </Paragraph>
        <ButtonGroup>
          <ButtonGroupItem
            onClick={() => props.onCurrencyChange("USD")}
            secondary={props.currency !== "USD"}
          >
            USD ($)
          </ButtonGroupItem>
          <ButtonGroupItem
            onClick={() => props.onCurrencyChange("GBP")}
            secondary={props.currency !== "GBP"}
          >
            GBP (£)
          </ButtonGroupItem>
          <ButtonGroupItem
            onClick={() => props.onCurrencyChange("AUD")}
            secondary={props.currency !== "AUD"}
          >
            AUD ($)
          </ButtonGroupItem>
        </ButtonGroup>
      </Box>
      <Box
        row
        gap="1rem"
        padding="1rem"
        width="100%"
        justifyContent="space-around"
        alignItems="center"
        flex={1}
        wrap
      >
        <BundleColumn credits={100} price={5} productID="prod_MVKl9tD8mvyqj4" currency={props.currency}>
          <Image
            src={credits100}
            alt="A teacher looking pleased having just bought 100 credits"
            width="1024"
            height="1024"
          />
        </BundleColumn>
        <BundleColumn credits={300} price={10} productID="prod_MVKkeFL9iPSffu" currency={props.currency}>
          <Image
            src={credits300}
            alt="A teacher looking pleased having just bought 300 credits"
            width="1024"
            height="1024"
          />
        </BundleColumn>
        <BundleColumn credits={1000} price={20} productID="prod_MVKgyXMEVCPD1V" currency={props.currency}>
          <Image
            src={credits1000}
            alt="A teacher looking pleased having just bought 1000 credits"
            width="1024"
            height="1024"
          />
        </BundleColumn>
      </Box>
      <Box
        column
        center
        gap="1rem"
        padding="1rem"
        maxWidth="40rem"
        margin="0 auto"
      >
        {!session.loggedIn && (
          <Paragraph>
            Please{" "}
            <Button onClick={() => (window.location.href = SIGNUP_URL)}>
              Sign Up
            </Button>{" "}
            to buy credits.
          </Paragraph>
        )}
        <Paragraph>
          Our credit bundles are the perfect way to get the reports you need.
          With our three bundle options, you can choose the right option for
          your needs.
        </Paragraph>

        <Paragraph>
          If you are interested in buying credits for your school, please <Link to="/contact">contact us</Link>.
          Help all your teachers save time and produce great reports that will
          impress parents. Track monthly usage and pay only for what you use.
        </Paragraph>
      </Box>
    </Box>
  );
};

export default PricingView;
