import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  Column,
  Heading2,
  Heading3,
  Select,
  Small,
  Span,
  Spreadsheet,
  Upload,
} from "../components";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Heading1, Paragraph, Strong } from "../components";
import { Student, StudyContext } from "../types";
import { ID, isEmptyRow } from "../utils";
import CSV from "../components/CSV";

export type SpreadsheetViewProps = {
  students: Student[];
  onImportStudents: (students: Student[]) => void;
  context: StudyContext;
};

const columns: Column<Student>[] = [
  { key: "name", label: "First Name", flex: 4 },
  { key: "surname", label: "Surname", flex: 4 },
  { key: "rawPronoun", label: "Pronoun", flex: 1 },
  { key: "rawHomework", label: "Homework Score", flex: 2 },
  { key: "rawAttendance", label: "Attendance Score", flex: 2 },
  { key: "rawParticipation", label: "Participation Score", flex: 2 },
  { key: "grade", label: "Grade", flex: 3 },
  { key: "comments", label: "Comments", flex: 5 },
  { key: "rawGradeSentiment", label: "Grade Sentiment", flex: 3 },
];

const SpreadsheetView: FC<SpreadsheetViewProps> = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Reports Writer | Import Students";
  }, []);

  const onUpload = useCallback(
    (file: File) => {
      // read each line of the file
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result;
        if (text) {
          const rows = CSV.parse(text.toString());
          const newStudents = rows
            .map((row) => {
              const s: Student = {
                id: ID(),
                name: "",
                surname: "",
                rawPronoun: "",
                pronoun: null,
                rawHomework: "",
                homework: null,
                rawAttendance: "",
                attendance: null,
                rawParticipation: "",
                participation: null,
                grade: "",
                rawGradeSentiment: "",
                gradeSentiment: null,
                comments: "",
                reports: [],
              };

              columns.forEach((column, index) => {
                s[column.key] = row[index] as never;
              });
              return s as Student;
            })
            .filter((s) => !isEmptyRow(s));

          props.onImportStudents(newStudents);
        }
      };
      reader.readAsText(file);
    },
    [props.onImportStudents]
  );

  return (
    <Box column gap="2rem" padding="2rem">
      <Box row center>
        <Heading1>Import Students from a Spreadsheet</Heading1>
      </Box>
      <Box row gap="1rem" wrap>
        <Box column gap="1rem" flex={1} minWidth="20rem">
          <Heading2>What is a CSV file?</Heading2>
          <Paragraph>
            A CSV File is a plain text file that stores data in a tabular
            format. Each row in the table is represented by a line in the file,
            and each column is separated by a comma. CSV files are commonly used
            for storing data from spreadsheet programs such as Excel.
          </Paragraph>
          <Paragraph>
            To create a CSV file from a spreadsheet program like Excel, you can
            use the "File {">"} Export" function. This will allow you to save
            the file in CSV format. When prompted, be sure to select "Comma
            Separated Values" as the file format.
          </Paragraph>
          <Paragraph>
            <ol>
              <li>Click File</li>
              <li>Click Save As</li>
              <li>
                In the "Save As" dialogue box, select "CSV (Comma Delimited)
                (*.csv)" from the "Save as type" drop-down menu
              </li>
              <li>Click Save</li>
            </ol>
          </Paragraph>
        </Box>
        <Box column gap="1rem" flex={1} minWidth="20rem">
          <Heading2>
            Upload a spreadsheet <Small>(CSV)</Small>
          </Heading2>
          <Paragraph>
            You can upload a spreadsheet of your students here.
          </Paragraph>
          <Paragraph>
            Do not include a header row. The first row of the spreadsheet should
            be the first student.
          </Paragraph>
          <Paragraph>
            The file should be a <Strong>CSV</Strong> file, with the following
            columns:
          </Paragraph>
          <Span>
            <ol style={{ margin: "0", listStyleType: "upper-alpha" }}>
              <li>
                First Name <Small>(required)</Small>
              </li>
              <li>
                Last Name <Small>(not used in report)</Small>
              </li>
              <li>
                Pronoun <Small>(recommended)</Small>
              </li>
              <li>
                Homework Score{" "}
                <Small>
                  (from unsatisfactory to excellent, choose from 1 to 9)
                </Small>
              </li>
              <li>
                Attendance Score{" "}
                <Small>
                  (from unsatisfactory to excellent, choose from 1 to 9)
                </Small>
              </li>
              <li>
                Participation Score{" "}
                <Small>
                  (from unsatisfactory to excellent, choose from 1 to 9)
                </Small>
              </li>
              <li>
                Grade <Small>(1 to 9 or F to A+)</Small>
              </li>
              <li>
                Comments <Small>(optional - adds flavour)</Small>
              </li>
              <li>
                Grade Sentiment <Small>(optional - overrides default)</Small>
              </li>
            </ol>
          </Span>
        </Box>
      </Box>
      <Box column maxWidth="60rem" margin="0 auto">
        <Upload onUpload={onUpload} />
      </Box>
      <Box column gap="1rem">
        <Heading3>{props.context.name}</Heading3>
        <Spreadsheet
          data={props.students}
          columns={columns}
        />
      </Box>
      <Box
        row
        reverse
        justifyContent="space-between"
        alignItems="center"
        gap="1rem"
      >
        <Button onClick={() => navigate("/workflows/generate")}>
          Write Reports
        </Button>
        <Link to="/sets">
          <Span>Back to all sets</Span>
        </Link>
      </Box>
    </Box>
  );
};

export default SpreadsheetView;
