import { FC, lazy, Suspense } from "react";

type IconProps = {
  size?: number;
  color?: string;
};

const LazyIconMdEdit = lazy(() =>
  import("react-icons/md").then((module) => ({ default: module.MdEdit }))
);

const LazyIconsMdArrowBack = lazy(() =>
  import("react-icons/md").then((module) => ({ default: module.MdArrowBack }))
);
const LazyIconsMdArrowForward = lazy(() =>
  import("react-icons/md").then((module) => ({
    default: module.MdArrowForward,
  }))
);
const LazyIconsMdChevronRight = lazy(() =>
  import("react-icons/md").then((module) => ({
    default: module.MdChevronRight,
  }))
);
const LazyIconsMdPerson = lazy(() =>
  import("react-icons/md").then((module) => ({ default: module.MdPerson }))
);
const LazyIconsMdSchool = lazy(() =>
  import("react-icons/md").then((module) => ({ default: module.MdSchool }))
);
const LazyIconsMdContentCopy = lazy(() =>
  import("react-icons/md").then((module) => ({ default: module.MdContentCopy }))
);
const LazyIconsMdDownload = lazy(() =>
  import("react-icons/md").then((module) => ({ default: module.MdDownload }))
);
const LazyIconsMdUpload = lazy(() =>
  import("react-icons/md").then((module) => ({ default: module.MdUpload }))
);

const MdEdit: FC<IconProps> = (props) => {
  return (
    <Suspense fallback={null}>
      <LazyIconMdEdit {...props} />
    </Suspense>
  );
};

const MdArrowBack: FC<IconProps> = (props) => {
  return (
    <Suspense fallback={null}>
      <LazyIconsMdArrowBack {...props} />
    </Suspense>
  );
};

const MdArrowForward: FC<IconProps> = (props) => {
  return (
    <Suspense fallback={null}>
      <LazyIconsMdArrowForward {...props} />
    </Suspense>
  );
};

const MdChevronRight: FC<IconProps> = (props) => {
  return (
    <Suspense fallback={null}>
      <LazyIconsMdChevronRight {...props} />
    </Suspense>
  );
};

const MdPerson: FC<IconProps> = (props) => {
  return (
    <Suspense fallback={null}>
      <LazyIconsMdPerson {...props} />
    </Suspense>
  );
};

const MdSchool: FC<IconProps> = (props) => {
  return (
    <Suspense fallback={null}>
      <LazyIconsMdSchool {...props} />
    </Suspense>
  );
};

const MdContentCopy: FC<IconProps> = (props) => {
  return (
    <Suspense fallback={null}>
      <LazyIconsMdContentCopy {...props} />
    </Suspense>
  );
};

const MdDownload: FC<IconProps> = (props) => {
  return (
    <Suspense fallback={null}>
      <LazyIconsMdDownload {...props} />
    </Suspense>
  );
};

const MdUpload: FC<IconProps> = (props) => {
  return (
    <Suspense fallback={null}>
      <LazyIconsMdUpload {...props} />
    </Suspense>
  );
};

export {
  MdArrowBack,
  MdArrowForward,
  MdChevronRight,
  MdPerson,
  MdSchool,
  MdContentCopy,
  MdDownload,
  MdUpload,
  MdEdit,
};
