import { FC, ReactNode, useCallback, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  MdPerson,
  MdSchool,
  Box,
  Button,
  ErrorLabel,
  Heading1,
  Heading3,
  Input,
  Paragraph,
  Select,
  Small,
  Span,
  Strong,
  TextArea,
  useSession,
} from "../components";
import { LOGIN_URL, SIGNUP_URL } from "../constants";
import useCraftPrompt from "../hooks/useCraftPrompt";
import usePostGenerate from "../hooks/usePostGenerate";
import { Student, StudentSet, StudyContext } from "../types";
import { ChooseStudent } from "./ChooseStudent";

export type GenerateViewProps = {
  studentSets: StudentSet[];
  studentSet: StudentSet;
  context: StudyContext;
  students: Student[];
  student: Student;

  onAddStudent: () => void;
  onSelectStudent: (id: string) => void;

  onAddReport: (content: string) => void;

  onNameChange: (name: string) => void;
  onSurnameChange: (surname: string) => void;
  onPronounChange: (pronoun: string) => void;
  onHomeworkChange: (homework: string) => void;
  onParticipationChange: (participation: string) => void;
  onAttendanceChange: (attendance: string) => void;
  onGradeChange: (grade: string) => void;
  onGradeSentimentChange: (gradeSentiment: string) => void;
  onCommentChange: (comment: string) => void;
};

const GenerateView: FC<GenerateViewProps> = (props) => {
  const session = useSession();

  useEffect(() => {
    document.title = "Reports Writer | Generate Report";
  }, []);

  const prompt = useCraftPrompt(props.student, props.studentSet.context);

  const navigate = useNavigate();

  const { generate, error, loading } = usePostGenerate();

  const onGenerate = useCallback(async () => {
    if (!props.studentSet.context.subject) {
      navigate("/workflows/subject");
    }
    if (props.student.name && prompt) {
      const content = await generate(prompt);
      props.onAddReport(content);
      navigate(`/workflows/reports`);
    }
  }, [
    generate,
    navigate,
    props.onAddReport,
    props.studentSet.context.subject,
    prompt,
  ]);

  let errorText: ReactNode | null = null;
  if (error) {
    errorText = error.message.includes("402") ? (
      <>
        You have run out of credits. Please{" "}
        <Link to="/pricing">purchase more credits</Link> to continue generating
        reports.
      </>
    ) : (
      <>Error: {error.message}</>
    );
  } else if (!session.loggedIn) {
    errorText = (
      <>
        <a href={SIGNUP_URL}>Sign up</a> for <Strong>10 free credits</Strong> or{" "}
        <a href={LOGIN_URL}>login</a> to generate reports.
      </>
    );
  }

  return (
    <Box column gap="2rem" padding="2rem">
      <Box column gap="1rem" maxWidth="40rem" margin="0 auto" width="100%">
        <Heading1>Generate Report</Heading1>
        <Paragraph>
          Select a student row from the spreadsheet, and{" "}
          <Strong>click the button below</Strong> to generate a report for that
          student.
        </Paragraph>
      </Box>

      <Box column gap="1rem" maxWidth="40rem" margin="0 auto" width="100%">
        <ChooseStudent
          student={props.student}
          studentSet={props.studentSet}
          students={props.students}
          onSelectStudent={(s) => props.onSelectStudent(s.id)}
        />
      </Box>

      <Box column gap="1rem" maxWidth="40rem" margin="0 auto" width="100%">
        <Box row>
          <Box row gap="1rem" flex={1}>
            <MdPerson size={18} />
            <Heading3>
              {props.student?.name} <Small>{props.student?.surname}</Small>
            </Heading3>
          </Box>

          <Box row gap="1rem" flex={1} justifyContent="flex-end">
            <MdSchool size={18} />
            <Heading3>{props.studentSet.context.subject}</Heading3>
          </Box>
        </Box>
        <Box row gap="1rem" width="100%" wrap>
          <Box flex={3}>
            <ErrorLabel
              error={!props.student?.name ? "Name is not provided" : null}
            >
              <Input
                label="First Name"
                value={props.student?.name}
                onChange={props.onNameChange}
              />
            </ErrorLabel>
          </Box>
          <Box flex={3}>
            <Input
              label="Surname"
              value={props.student?.surname}
              onChange={props.onSurnameChange}
            />
          </Box>
          <Box flex={1}>
            <ErrorLabel
              error={
                props.student.rawPronoun && !props.student.pronoun
                  ? "Pronoun is not recognised"
                  : null
              }
            >
              <Input
                label="Pronoun (she, he, they)"
                value={props.student?.rawPronoun}
                onChange={props.onPronounChange}
              />
            </ErrorLabel>
          </Box>
        </Box>
        <Box row gap="1rem" width="100%" wrap>
          <ErrorLabel
            error={
              props.student.rawHomework && !props.student.homework
                ? "Homework invalid"
                : null
            }
          >
            <Input
              label="Homework (1-9)"
              value={props.student?.rawHomework}
              onChange={props.onHomeworkChange}
            />
          </ErrorLabel>
          <ErrorLabel
            error={
              props.student.rawAttendance && !props.student.attendance
                ? "Attendance invalid"
                : null
            }
          >
            <Input
              label="Attendance (1-9)"
              value={props.student?.rawAttendance}
              onChange={props.onAttendanceChange}
            />
          </ErrorLabel>
          <ErrorLabel
            error={
              props.student.rawParticipation && !props.student.participation
                ? "Participation invalid"
                : null
            }
          >
            <Input
              label="Participation (1-9)"
              value={props.student?.rawParticipation}
              onChange={props.onParticipationChange}
            />
          </ErrorLabel>
        </Box>
        <Box row gap="1rem" width="100%">
          <Box flex={2}>
            <ErrorLabel
              error={
                props.student.grade && !props.student.gradeSentiment
                  ? "Invalid grade"
                  : null
              }
            >
              <Input
                label={`Grade (${props.context?.gradeSystem})`}
                value={props.student?.grade}
                onChange={props.onGradeChange}
              />
            </ErrorLabel>
          </Box>
          <Box flex={1}>
            <ErrorLabel
              error={
                props.student.rawGradeSentiment && !props.student.gradeSentiment
                  ? "Invalid sentiment"
                  : null
              }
            >
              <Input
                label="Grade Sentiment (1 - 9)"
                value={props.student?.rawGradeSentiment}
                onChange={props.onGradeSentimentChange}
              />
            </ErrorLabel>
          </Box>
        </Box>
        <Box row gap="1rem" width="100%">
          <Box flex={1}>
            <ErrorLabel
              error={
                props.student?.comments
                  ? null
                  : "Recommended to add personal comments"
              }
            >
              <TextArea
                label="Additional Comments (adds flavour)"
                value={props.student?.comments}
                onChange={props.onCommentChange}
              />
            </ErrorLabel>
          </Box>
        </Box>
      </Box>

      <Box column gap="1rem" maxWidth="40rem" margin="0 auto">
        <Paragraph>
          After you have filled in the relevant information for a student, you
          can <Strong>generate a report for that student</Strong>.
        </Paragraph>
        <Box
          row
          reverse
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          gap="2rem"
        >
          <ErrorLabel error={errorText}>
            <Button
              disabled={!session.loggedIn || !props.student?.name || loading}
              onClick={onGenerate}
            >
              {loading ? "Loading..." : "Generate"}
            </Button>
          </ErrorLabel>
          <Link to="/workflows/subject">
            <Span>Previous</Span>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default GenerateView;
