import { FC, useEffect } from "react";
import { Box, Heading1, Paragraph } from "../components";

const ContactSupportView: FC = () => {
  useEffect(() => {
    document.title = "Reports Writer | Contact Support";
  }, []);

  return (
    <Box
      column
      gap="1rem"
      flex={1}
      padding="4rem"
      maxWidth="40rem"
      margin="0 auto"
      alignItems="center"
    >
      <Heading1>Contact Support</Heading1>
      <Paragraph>
        Please contact us at{" "}
        <a href="mailto:otoolej012@gmail.com">otoolej012@gmail.com</a>
      </Paragraph>
    </Box>
  );
};

export default ContactSupportView;
