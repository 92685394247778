import styled from "@emotion/styled";

const Label = styled.label`
  color: #4f4f4f;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0.5rem;
  display: block;
  white-space: nowrap;
`;

export default Label;
