import { ReactNode } from "react";
import styled from "@emotion/styled";
import { Link, NavLink } from "react-router-dom";
import Box from "./Box";

const BaseNavBar = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 0 0.5rem #ddd;
  flex-wrap: wrap;
`;

const BaseNavItem = styled.span<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: "Roboto", sans-serif;
  font-size: 1rem;

  color: #222;
  text-decoration: ${(props) => (props.active ? "underline" : "none")};

  &:hover {
    cursor: pointer;
  }
`;

const LinkWrapper = styled.span`
  a {
    text-decoration: none;
  }
`;

type NavBarProps = {
  to: string;
  src: string;
  alt: string;
  children: ReactNode;
};

type NavItemProps = {
  to: string;
  children: ReactNode;
};

const Logo = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 0.5rem;
`;

const isSsr = typeof window === "undefined";

export const NavBar = ({ to, src, alt, children }: NavBarProps) => {
  return (
    <BaseNavBar>
      <Link to={to}>
        <Box center>
          <Logo src={isSsr ? `/${src}` : src} alt={alt} />
        </Box>
      </Link>
      <Box
        flex={1}
        justifyContent="flex-end"
        alignItems="center"
        gap="2rem"
        padding="1rem 2rem"
        wrap
      >
        {children}
      </Box>
    </BaseNavBar>
  );
};

export const NavItem = ({ to, children }: NavItemProps) => {
  if (to.startsWith("http")) {
    return (
      <LinkWrapper>
        <a href={to}>
          <BaseNavItem>{children}</BaseNavItem>
        </a>
      </LinkWrapper>
    );
  }

  return (
    <LinkWrapper>
      <NavLink to={to}>
        {({ isActive }) => (
          <BaseNavItem active={isActive}>{children}</BaseNavItem>
        )}
      </NavLink>
    </LinkWrapper>
  );
};
