import { FC, useEffect } from "react";
import { Heading1, Heading2, Box, Paragraph } from "../../components";
import { Link } from "react-router-dom";

const BlogIndexView: FC = () => {
  useEffect(() => {
    document.title = "Reports Writer | Blog";
  }, []);

  return (
    <Box column gap="1rem" flex={1} padding="4rem" maxWidth="40rem" margin="0 auto">
      <Heading1>Blog</Heading1>
      <Paragraph>
        Our blog is a place where we share our thoughts on education, technology, and the future of education technology.
      </Paragraph>
      <Heading2>Posts</Heading2>
      <Box column gap="2rem">
        <Paragraph>
          <Link to="/blog/are-school-reports-worth-it">
            Are School Reports Worth It?
          </Link>
        </Paragraph>
        <Paragraph>
          <Link to="/blog/how-much-time-do-teachers-spend-on-reports">
            How Much Time Do Teachers Spend On Reports?
          </Link>
        </Paragraph>
        <Paragraph>
          <Link to="/blog/the-problem-with-report-comment-banks">
            The Problem With Report Comment Banks
          </Link>
        </Paragraph>
      </Box>
    </Box>
  );
}

export { BlogIndexView };
