import styled from "@emotion/styled";
import { FC, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Heading1,
  Paragraph,
  Box,
  Button,
  Strong,
  Heading2,
  Span,
  useSession,
} from "../components";
import { SIGNUP_URL } from "../constants";
import promo from "../promo.webp";
import ExampleReports from "./ExampleReports";

const PromoImg = styled.img`
  width: 100%;
  max-width: 100vh;
  margin: 0 auto;
  display: block;
  height: auto;
`;

const RootView: FC = () => {
  const navigate = useNavigate();
  const session = useSession();

  useEffect(() => {
    document.title = "Reports Writer | Teacher School Report Writing Assistant";
  }, []);

  return (
    <Box column padding="2rem" gap="2rem" flex={1} width="100%">
      <Box
        row
        reverse
        gap="2rem"
        wrap
        flex={1}
        maxWidth="60rem"
        margin="0 auto"
      >
        <Box
          flex={1}
          column
          gap="1rem"
          justifyContent="center"
          minWidth="20rem"
        >
          <Heading1>Reports Writer</Heading1>
          <Heading2>Teacher School Report Writing Assistant</Heading2>
          <Paragraph>
            Reports Writer{" "}
            <Strong>assists teachers in writing end of year reports</Strong> for
            their students. It is a web application that allows teachers to
            create a spreadsheet of their students, and then{" "}
            <Strong>generate a report for each student</Strong>.
          </Paragraph>
          <Paragraph>
            Write better reports, faster. Generate prose reports customised to
            your students, with perfect grammar and spelling. Much more powerful
            than comment bank tools.
          </Paragraph>
          {!session.loggedIn && (
            <Paragraph>
              <a href={SIGNUP_URL}>
                <Span>Sign up</Span>
              </a>{" "}
              for <Strong>10 free credits</Strong>.
            </Paragraph>
          )}
          <Box row reverse width="100%" center gap="1rem">
            {session.loggedIn ? (
              <>
                <Button onClick={() => navigate("/workflows/subject")}>
                  Get Started
                </Button>
              </>
            ) : (
              <>
                <Button onClick={() => (window.location.href = SIGNUP_URL)}>
                  Sign Up
                </Button>
                <Link to="/workflows/subject">
                  <Span>Take a look first</Span>
                </Link>
              </>
            )}
          </Box>
        </Box>
        <Box flex={1} column minWidth="20rem">
          <PromoImg
            src={promo}
            alt="A cartoon of two teachers talking: How are you not stressed? Have you not heard of... Reports Writer!"
            width="1024"
            height="1024"
          />
        </Box>
      </Box>
      <ExampleReports />
    </Box>
  );
};

export default RootView;
