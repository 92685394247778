import {
  ComponentType,
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

type SessionState = {
  loggedIn: boolean;
  username?: string;
  token?: string;
  groups?: string[];
};

type Session = SessionState & {
  login: () => void;
  logout: () => void;
};

const useActiveSession = (): Session => {
  const [{ loggedIn, username, token, groups }, setSession] = useState<SessionState>({
    loggedIn: false,
  });
  useEffect(() => {
    (async () => {
      let user: any = {};
      try {
        user = await Auth.currentAuthenticatedUser();
      } catch (e) {
        // do nothing
      }
      const loggedIn = Object.keys(user).length > 0;
      if (!loggedIn) {
        setSession({ loggedIn: false });
        return;
      }
      const username = user.attributes.email;
      const token = user.signInUserSession.idToken.jwtToken;
      const groups = user.signInUserSession.idToken.payload["cognito:groups"];
      setSession({ loggedIn, username, token, groups });
    })();
  }, []);

  const login = useCallback(async () => {
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Cognito,
    });
  }, []);

  const logout = useCallback(async () => {
    await Auth.signOut();
    setSession({ loggedIn: false });
  }, []);

  return { loggedIn, username, token, login, logout, groups };
};

const SessionContext = createContext<Session>({
  loggedIn: false,
  login: () => {},
  logout: () => {},
});

const withAuthorizer = <T extends object>(
  Component: ComponentType<T & { session: Session }>
): FC<T> => {
  return (props) => {
    const session = useActiveSession();
    return (
      <SessionContext.Provider value={session}>
        <Component {...props} session={session} />
      </SessionContext.Provider>
    );
  };
};

const useSession = (): Session => {
  return useContext(SessionContext);
};

export { withAuthorizer, useSession, Session, SessionContext };
