import API from "@aws-amplify/api";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Heading1,
  Paragraph,
  Box,
  Button,
  Strong,
  Heading2,
  useSession,
} from "../components";
import { API_NAME } from "../constants";

// on mount useEffect fetches credits url and sets body to credits useState
// account h1 heading
// email address paragraph
// credits paragraph and heading
// logout button that calls session.logout
const AccountView: FC = () => {
  const session = useSession();
  const navigate = useNavigate();
  const [credits, setCredits] = useState<number | null>(null);

  useEffect(() => {
    document.title = "Reports Writer | Account";
  }, []);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (!session.loggedIn) {
        navigate("/");
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, [session.loggedIn]);

  useEffect(() => {
    if (session.token) {
      API.get(API_NAME, "/credits", {
        headers: {
          Authorization: session.token,
        },
      }).then((response) => {
        setCredits(parseInt(response));
      });
    }
  }, [session.token, session.loggedIn]);

  return (
    <Box column margin="0 auto" padding="2rem" gap="2rem" maxWidth="40rem" wrap>
      <Heading1>Account</Heading1>
      <Paragraph>{session?.username}</Paragraph>
      {session.groups?.length && <Heading2>Institutions</Heading2>}
      {session.groups?.map((group) => (
        <Paragraph key={group}>
          {group.replace("_", " ").replace("-", " ")}
        </Paragraph>
      ))}
      <Heading2>Credits</Heading2>
      <Paragraph>
        {credits === null ? (
          "Loading..."
        ) : (
          <>
            <Strong>{credits}</Strong> credits
          </>
        )}
      </Paragraph>
      <Box row width="100%">
        <Button onClick={session.logout}>Log Out</Button>
      </Box>
    </Box>
  );
};

export default AccountView;
