import { Children, FC, ReactNode } from "react";
import styled from "@emotion/styled";

type CarouselProps = {
  children: ReactNode;
  activeIndex: number;
};

const BaseCarousel = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  background-color: #eee;
  border-radius: 0.5rem;
  width: 100%;
`;

const BaseCarouselItem = styled.div`
  flex: 0 0 100%;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease, opacity 0.5s ease;
`;
const CarouselItem: FC<CarouselItemProps> = (props) => {
  return (
    <BaseCarouselItem
      style={{
        transform: `translateX(${-100 * props.activeIndex}%)`,
        opacity: props.activeIndex === props.index ? 1 : 0.2,
        pointerEvents: props.activeIndex === props.index ? "all" : "none",
        cursor: props.activeIndex === props.index ? "initial" : "default",
      }}
    >
      {props.children}
    </BaseCarouselItem>
  );
};

type CarouselItemProps = {
  children: ReactNode;
  activeIndex: number;
  index: number;
};

const Carousel: FC<CarouselProps> = ({ activeIndex, children }) => {
  return (
    <BaseCarousel>
      {Children.map(children, (child, index) => {
        return (
          <CarouselItem activeIndex={activeIndex} index={index} key={index}>
            {child}
          </CarouselItem>
        );
      })}
    </BaseCarousel>
  );
};

export default Carousel;
