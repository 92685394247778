import styled from "@emotion/styled";
import { FC, useMemo } from "react";
import Label from "./Label";
import Box from "./Box";

type InputProps = {
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};

const BaseInput = styled.input`
  background-color: #ffffee;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  color: #4f4f4f;
  font-size: 1rem;
  padding: 1rem;
  width: 100%;
  font-family: "Roboto", sans-serif;
  display: block;
`;

const Input: FC<InputProps> = (props) => {
  const id = useMemo(
    () => props.label.replace(" ", "-").toLowerCase(),
    [props.label]
  );
  return (
    <Box column width="100%" maxWidth="30rem" flex={1}>
      <Label htmlFor={id}>{props.label}</Label>
      <BaseInput
        id={id}
        value={props.value}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </Box>
  );
};

export default Input;
