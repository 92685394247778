import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Paragraph,
  Select,
  Small,
  Span,
  Strong,
  TimeString,
  Card,
  Carousel,
  IconButton,
  MdDownload,
  MdArrowForward,
  DateString,
  MdContentCopy,
  MdArrowBack,
} from "../components";
import { Report, Student, StudentSet } from "../types";
import { useWriteToClipboard } from "../hooks";
import { useSwipeable } from "react-swipeable";
import CSV from "../components/CSV";
import { ChooseStudent } from "./ChooseStudent";

export type ReportsViewProps = {
  studentSet: StudentSet;
  student: Student;
  students: Student[];
  onSelectStudent: (id: string) => void;
  onAddStudent: () => void;
  reports: Report[];
};

const ReportsView: FC<ReportsViewProps> = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Reports Writer | Reports";
  }, []);

  const { writeToClipboard } = useWriteToClipboard();
  const [reportIndex, setReportIndex] = useState(0);

  const handleNext = useCallback(() => {
    if (reportIndex < props.reports.length - 1) {
      setReportIndex(reportIndex + 1);
    }
  }, [reportIndex, props.reports.length]);

  const handlePrev = useCallback(() => {
    if (reportIndex > 0) {
      setReportIndex(reportIndex - 1);
    }
  }, [reportIndex]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
  });

  useEffect(() => {
    setReportIndex(0);
  }, [props.student]);

  const handleExport = useCallback(() => {
    const data = props.students.map((student) => [
      student.name,
      student.surname,
      student.rawPronoun,
      student.rawHomework,
      student.rawAttendance,
      student.rawParticipation,
      student.grade,
      student.comments,
      student.rawGradeSentiment,
      ...student.reports.map((r) => r.content),
    ]);
    const csvString = CSV.stringify(data);
    const csvContent = `data:text/csv;charset=utf-8,${csvString}`;
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }, [props.students, props.reports]);

  const handleAnother = useCallback(() => {
    props.onAddStudent();
    navigate("/workflows/generate");
  }, [navigate]);

  return (
    <Box column gap="2rem" padding="2rem" overflowHidden>
      <Box column gap="1rem" maxWidth="40rem" margin="0 auto" width="100%">
        <Heading1>Reports</Heading1>
        <Paragraph>
          This is where you can view all of the reports that you have generated.
        </Paragraph>
      </Box>
      <Box column gap="1rem" maxWidth="40rem" margin="0 auto" width="100%">
        <ChooseStudent
          student={props.student}
          studentSet={props.studentSet}
          students={props.students}
          onSelectStudent={(s) => props.onSelectStudent(s.id)}
        />
      </Box>
      <Box column gap="1rem" maxWidth="40rem" margin="0 auto" width="100%">
        {props.reports?.length > 0 && (
          <>
            <Box row gap="1rem" justifyContent="space-between">
              <IconButton
                label="Previous"
                onClick={handlePrev}
                disabled={reportIndex === 0}
              >
                <MdArrowBack size={24} />
              </IconButton>

              <IconButton
                label="Next"
                onClick={handleNext}
                disabled={reportIndex === props.reports.length - 1}
              >
                <MdArrowForward size={24} />
              </IconButton>
            </Box>
            <div {...swipeHandlers}>
              <Carousel activeIndex={reportIndex}>
                {props.reports.map((report) => (
                  <Card key={report.id}>
                    <Box row justifyContent="space-between" alignItems="center">
                      <Box column flex={1} gap="0.5rem">
                        <Heading3>
                          {props.student.name}{" "}
                          <Small>{props.student.surname}</Small>
                        </Heading3>
                        <Heading4>
                          <DateString date={report.date} />{" "}
                          <Small>
                            <TimeString date={report.date} />
                          </Small>
                        </Heading4>
                      </Box>
                      <Box column>
                        <IconButton
                          label="Copy"
                          onClick={() => writeToClipboard(report.content)}
                        >
                          <Box row gap="0.5rem" alignItems="center">
                            <Span>Copy</Span>
                            <MdContentCopy size={24} />
                          </Box>
                        </IconButton>
                      </Box>
                    </Box>
                    <Paragraph>{report.content}</Paragraph>
                    <Paragraph>
                      {report.content && (
                        <Small>
                          {report.content.split(" ").length} words,{" "}
                          {report.content.length} characters
                        </Small>
                      )}
                    </Paragraph>
                  </Card>
                ))}
              </Carousel>
            </div>
          </>
        )}
        {!props.reports?.length && (
          <Box width="100%" center padding="2rem 0">
            <Paragraph>
              No reports have been generated for this student yet.
            </Paragraph>
          </Box>
        )}
        <Box column width="100%" gap="1rem">
          <Paragraph>
            <Strong>Return</Strong> to the generate another report for a
            student. You can come back to this page to view the reports that you
            have generated.
          </Paragraph>
          <Box row width="100%" justifyContent="space-between">
            {props.reports.length > 0 ? (
              <Button onClick={() => handleAnother()}>
                Add Another Student
              </Button>
            ) : (
              <Button onClick={() => navigate("/workflows/generate")}>
                Back to Generate
              </Button>
            )}
            {props.reports.length > 0 && (
              <IconButton label="Export Reports" onClick={handleExport}>
                <MdDownload size={24} />
                Export Reports
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReportsView;
