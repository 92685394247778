import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dropdown,
  Heading1,
  Input,
  Paragraph,
  Strong,
} from "../components";
import { GradeSystem, StudyContext } from "../types";

export type ContextViewProps = {
  context: StudyContext;
  onSetNameChange: (name: string) => void;
  onSubjectChange: (subject: string) => void;
  onGradeSystemChange: (gradeSystem: GradeSystem) => void;
};

const gradeSystemChoices = [
  { label: "UK Grades: 9 - 1", id: "uk", value: GradeSystem.UK },
  { label: "US Grades: A+ - F", id: "us", value: GradeSystem.US },
  { label: "AUS Grades: A - F", id: "au", value: GradeSystem.AU },
];

const ContextView: FC<ContextViewProps> = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Reports Writer | Student Set and Subject";
  }, []);
  return (
    <Box maxWidth="40rem" column margin="0 auto" padding="2rem" gap="1rem">
      <Heading1>Getting Started</Heading1>
      <Paragraph>
        Tell us what subject were the students were studying. This will help us{" "}
        <Strong>generate reports that are relevant to your students</Strong>.
      </Paragraph>
      <Box maxWidth="30rem" column gap="1rem" padding="1rem 0">
        <Input
          label="Set Name"
          value={props.context.name}
          placeholder="Class 1"
          onChange={props.onSetNameChange}
        />
        <Input
          label="Subject"
          value={props.context.subject}
          placeholder="Maths"
          onChange={props.onSubjectChange}
        />
        <Dropdown
          label="Grade System"
          value={
            gradeSystemChoices.find(
              (choice) => choice.value === props.context.gradeSystem
            ) ?? gradeSystemChoices[0]
          }
          options={gradeSystemChoices}
          onChange={props.onGradeSystemChange}
        />
        <Box column gap="1rem">
          <Paragraph>
            You can come back and change this information later.
          </Paragraph>
          <Box row reverse width="100%">
            <Button
              disabled={!props.context.subject || !props.context.name}
              onClick={() => navigate("/workflows/generate")}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContextView;
