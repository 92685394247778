import styled from "@emotion/styled";
import { ReactElement, useMemo, useState } from "react";
import Box from "./Box";

// a select component that is a scrollable and searchable list of options

type SelectProps<T> = {
  label: string;
  options: T[];
  value: T;
  onChange: (value: T) => void;
};

const BaseSelect = styled.div`
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  color: #4f4f4f;
  font-size: 1rem;
  width: 100%;
  font-family: "Roboto", sans-serif;
  display: block;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BaseLabel = styled.label`
  color: #4f4f4f;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0.5rem;
  display: block;
`;

const BaseSelectOption = styled.li<{ active: boolean }>`
  display: flex;
  color: #4f4f4f;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;

  &:first-child {
    border-radius: 0.5rem 0.5rem 0 0;
  }

  &:last-child {
    border-radius: 0 0 0.5rem 0.5rem;
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ active }) => (active ? "#eeeeff" : "#ffffee")};
    text-decoration: underline;
  }

  ${(props) =>
    props.active &&
    `
        background-color: #eeeeff;
        font-weight: 700;
    `}
`;

interface Option {
  id: string;
  label: string;
}

const filterFunc = (search: string, value: Option) => (option: Option) =>
  option.id === value.id ||
  option.label.toLowerCase().includes(search.toLowerCase());

const Select: <T extends Option>(
  p: SelectProps<T>
) => ReactElement<SelectProps<T>> = (props) => {
  const id = useMemo(
    () => props.label.replace(" ", "-").toLowerCase(),
    [props.label]
  );
  return (
    <Box column width="100%" gap="1rem">
      <BaseLabel htmlFor={id}>{props.label}</BaseLabel>
      <BaseSelect id={id}>
        {props.options.map((option) => (
          <BaseSelectOption
            key={option.id}
            active={option.id === props.value.id}
            onClick={() => props.onChange(option)}
          >
            {option.label}
          </BaseSelectOption>
        ))}
      </BaseSelect>
    </Box>
  );
};

export default Select;
