import { FC, useMemo } from "react";

type DateProps = {
  date: Date;
};

const DateString: FC<DateProps> = (props) => {
  const date = useMemo(() => new Date(props.date), [props.date]);
  const dateString: string = useMemo(() => {
    if (!date) return "";
    return date.toDateString();
  }, [date]);
  return <>{dateString}</>;
};

const TimeString: FC<DateProps> = (props) => {
  const date = useMemo(() => new Date(props.date), [props.date]);
  const time: string = useMemo(() => {
    if (!date) return "";
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    return `${hours % 12 || 12}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
  }, [date]);
  return <>{time}</>;
};

export { DateString, TimeString };
