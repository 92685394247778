import { FC, useEffect } from "react";
import {
  Box,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Paragraph,
  Span,
} from "../components";

const firstParagraph =
  `Reports Writer takes your privacy and data security seriously. ` +
  `All data that you input into the interface is stored locally on your device. ` +
  `Reports Writer does not store any user data on their servers. ` +
  `This means that your data is only accessible to you and is never shared with anyone else. `;

const secondParagraph =
  `Prompts are sent to the server, which never include student's surnames. ` +
  `These prompts are only accessible by web administrators for the purpose of monitoring for abuse of the service, prompts are kept for no longer than 30 days and are securely stored with limited access. ` +
  `The privacy and security of student data is a primary concern in the design of the software. `;

const PrivacyPolicyView: FC = () => {
  useEffect(() => {
    document.title = "Reports Writer | Privacy Policy";
  }, []);

  return (
    <Span>
      <Box
        column
        gap="1rem"
        flex={1}
        padding="4rem"
        maxWidth="40rem"
        margin="0 auto"
      >
        <Heading1>Privacy Policy</Heading1>
        <Paragraph>{firstParagraph}</Paragraph>
        <Paragraph>{secondParagraph}</Paragraph>

        <Heading2>Information Collection And Use</Heading2>

        <Paragraph>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </Paragraph>

        <Heading3>Cookies and Usage Data</Heading3>

        <Paragraph>
          We use cookies and similar tracking technologies to track the activity
          on our Service and hold certain information.
        </Paragraph>

        <Heading3>Types of Data Collected</Heading3>

        <Heading4>Personal Data</Heading4>

        <Paragraph>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you ("Personal Data"). Personally identifiable information
          may include, but is not limited to:
        </Paragraph>

        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Usage Data</li>
        </ul>

        <Heading4>Usage Data</Heading4>

        <Paragraph>
          We may also collect information how the Service is accessed and used
          ("Usage Data"). This Usage Data may include information such as your
          computer's Internet Protocol address (e.g. IP address), browser type,
          browser version, the pages of our Service that you visit, the time and
          date of your visit, the time spent on those pages, unique device
          identifiers and other diagnostic data.
        </Paragraph>

        <Heading4>Tracking & Cookies Data</Heading4>

        <Paragraph>
          We use cookies and similar tracking technologies to track the activity
          on our Service and we hold certain information.
        </Paragraph>

        <Paragraph>
          Cookies are files with small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Tracking technologies also used are
          beacons, tags, and scripts to collect and track information and to
          improve and analyze our Service.
        </Paragraph>

        <Paragraph>
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Service.
        </Paragraph>

        <Paragraph>Examples of Cookies we use:</Paragraph>

        <ul>
          <li>
            <strong>Session Cookies.</strong> We use Session Cookies to operate
            our Service.
          </li>
          <li>
            <strong>Preference Cookies.</strong> We use Preference Cookies to
            remember your preferences and various settings.
          </li>
          <li>
            <strong>Security Cookies.</strong> We use Security Cookies for
            security purposes.
          </li>
        </ul>

        <Heading2>Use of Data</Heading2>

        <Paragraph>
          Reports Writer uses the collected data for various purposes:
        </Paragraph>

        <ul>
          <li>To provide and maintain the Service</li>
          <li>To notify you about changes to our Service</li>
          <li>
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li>To provide customer care and support</li>
          <li>
            To provide analysis or valuable information so that we can improve
            the Service
          </li>
          <li>To monitor the usage of the Service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>

        <Heading2>Transfer Of Data</Heading2>

        <Paragraph>
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ than those from your jurisdiction.
        </Paragraph>

        <Paragraph>
          If you are located outside United States and choose to provide
          information to us, please note that we transfer the data, including
          Personal Data, to United States and process it there.
        </Paragraph>

        <Paragraph>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </Paragraph>

        <Paragraph>
          Reports Writer will take all steps reasonably necessary to ensure that
          your data is treated securely and in accordance with this Privacy
          Policy and no transfer of your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of your data and other personal information.
        </Paragraph>

        <Heading2>Disclosure Of Data</Heading2>

        <Heading3>Legal Requirements</Heading3>

        <Paragraph>
          Reports Writer may disclose your Personal Data in the good faith
          belief that such action is necessary to:
        </Paragraph>

        <ul>
          <li>To comply with a legal obligation</li>
          <li>
            To protect and defend the rights or property of Reports Writer
          </li>
          <li>
            To prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            To protect the personal safety of users of the Service or the public
          </li>
          <li>To protect against legal liability</li>
        </ul>

        <Heading2>Security Of Data</Heading2>

        <Paragraph>
          The security of your data is important to us, but remember that no
          method of transmission over the Internet, or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </Paragraph>

        <Heading2>Service Providers</Heading2>

        <Paragraph>
          We may employ third party companies and individuals to facilitate our
          Service ("Service Providers"), to provide the Service on our behalf,
          to perform Service-related services or to assist us in analyzing how
          our Service is used.
        </Paragraph>

        <Paragraph>
          These third parties have access to your Personal Data only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.
        </Paragraph>

        <Heading2>Links To Other Sites</Heading2>

        <Paragraph>
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit. We have no control over and assume no
          responsibility for the content, privacy policies or practices of any
          third party sites or services.
        </Paragraph>

        <Heading2>Children's Privacy</Heading2>

        <Paragraph>
          Our Service does not address anyone under the age of 18 ("Children").
        </Paragraph>

        <Paragraph>
          We do not knowingly collect personally identifiable information from
          anyone under the age of 18. If you are a parent or guardian and you
          are aware that your Children has provided us with Personal Data,
          please contact us. If we become aware that we have collected Personal
          Data from children without verification of parental consent, we take
          steps to remove that information from our servers.
        </Paragraph>

        <Heading2>Changes To This Privacy Policy</Heading2>

        <Paragraph>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </Paragraph>

        <Paragraph>
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update the
          "effective date" at the top of this Privacy Policy.
        </Paragraph>

        <Paragraph>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </Paragraph>
      </Box>
    </Span>
  );
};

export default PrivacyPolicyView;
