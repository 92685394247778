import styled from "@emotion/styled";
import Label from "./Label";
import Box from "./Box";

type Choice<T> = {
  id: string;
  label: string;
  value: T;
};

const Select = styled.select`
  background-color: #ffffee;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  font-family: "Roboto", sans-serif;
  color: #222;
  font-size: 1rem;
  padding: 1rem;
  width: 100%;
  display: block;
`;

const Option = styled.option`
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  color: #222;
  font-size: 1rem;
`;

export type DropdownProps<T> = {
  label: string;
  options: Choice<T>[];
  onChange: (value: T) => void;
  value: Choice<T> | null;
};

const Dropdown = <T,>(props: DropdownProps<T>): JSX.Element => {
  const id = props.label.replace(" ", "-").toLowerCase();
  return (
    <Box column width="100%" maxWidth="30rem" flex={1}>
      <Label htmlFor={id}>{props.label}</Label>
      <Select
        id={id}
        value={props.value?.id}
        onChange={(event) => {
          const option = props.options.find(
            (option) => option.id === event.target.value
          );
          if (option) {
            props.onChange(option.value);
          }
        }}
      >
        {props.options.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Box>
  );
};

export default Dropdown;
