import { FC, ReactNode, Children } from "react";
import { MdChevronRight } from "./Icons";
import Box from "./Box";

type StepsProps = {
  children: ReactNode;
};

const Steps: FC<StepsProps> = (props) => {
  return (
    <Box
      row
      center
      width="100%"
      gap="1rem"
      padding="1rem"
      maxWidth="40rem"
      margin="0 auto"
      justifyContent="space-between"
      alignItems="center"
      flex={1}
      wrap
    >
      {Children.map(props.children, (child, index) => (
        <>
          <Box key={index} row center flex={1}>
            {child}
          </Box>
          {index < Children.count(props.children) - 1 && (
            <MdChevronRight size={20} />
          )}
        </>
      ))}
    </Box>
  );
};

export { Steps };
