type ProductID = "prod_MVKl9tD8mvyqj4" | "prod_MVKkeFL9iPSffu" | "prod_MVKgyXMEVCPD1V";

type Currency = "GBP" | "AUD" | "USD";

type PaymentLinkBody = {
  productID: ProductID;
  currency: Currency;
};

enum GradeSystem {
  UK = "UK",
  US = "US",
  AU = "AU",
}

enum Pronoun {
  He = "he",
  She = "she",
  They = "they",
}

type Report = {
  id: string;
  date: Date;
  content: string;
};

type Sentiment = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

type Student = {
  id: string;
  name: string;
  surname: string;
  rawPronoun: string;
  pronoun: Pronoun | null;
  rawHomework: string;
  homework: Sentiment | null;
  rawAttendance: string;
  attendance: Sentiment | null;
  rawParticipation: string;
  participation: Sentiment | null;
  grade: string;
  rawGradeSentiment: string;
  gradeSentiment: Sentiment | null;
  comments: string;
  reports: Report[];
};

type StudyContext = {
  name: string;
  subject: string;
  gradeSystem: GradeSystem | null;
};

type StudentSet = {
  id: string;
  context: StudyContext;
  students: Student[];
};

type Store = {
  studentSets: StudentSet[];
};

type Selected = {
  studentId: string;
  studentSetId: string;
};

export {
  GradeSystem,
  Pronoun,
  Sentiment,
  Student,
  StudyContext,
  StudentSet,
  Store,
  Selected,
  Report,
  PaymentLinkBody,
  ProductID,
  Currency,
};
