import { Sentiment, GradeSystem } from "../types";

const ukGradeSentiment = (str: string): Sentiment | null => {
  const n = parseInt(str, 10);
  if (n >= 1 && n <= 9) {
    return n as Sentiment;
  }
  return null;
};

const usGradeSentiment = (str: string): Sentiment | null => {
  const letter = str.trim()[0].toUpperCase();
  const plusMinus = str.trim()[1];
  const minus = plusMinus === "-";

  if (letter === "A") {
    return minus ? 8 : 9;
  }
  if (letter === "B") {
    return minus ? 6 : 7;
  }
  if (letter === "C") {
    return minus ? 4 : 5;
  }
  if (letter === "D") {
    return minus ? 2 : 3;
  }
  if (letter === "F") {
    return 1;
  }
  return null;
};

const auGradeSentiment = (str: string): Sentiment | null => {
  const letter = str.trim()[0].toUpperCase();

  switch (letter) {
    case "A":
      return 9;
    case "B":
      return 7;
    case "C":
      return 5;
    case "D":
      return 3;
    case "E":
      return 2;
    case "F":
      return 1;
    default:
      return null;
  }
};

const useGradeSentiment = (
  _grade: string,
  gradeSystem: GradeSystem
): Sentiment | null => {
  const grade = _grade.toString(); // make a copy
  switch (gradeSystem) {
    case GradeSystem.UK:
      return ukGradeSentiment(grade);
    case GradeSystem.US:
      return usGradeSentiment(grade);
    case GradeSystem.AU:
      return auGradeSentiment(grade);
    default:
      return null;
  }
};

export default useGradeSentiment;
