import { Student, StudentSet, StudyContext } from "./types";

const ID = (): string => {
  // 16 characters in length, digits and letters only
  return Math.random().toString(36).substr(2, 16);
};

const isEmptyRow = (row?: Student | null): boolean =>
  row == null ||
  (!row.name &&
    !row.surname &&
    !row.rawPronoun &&
    !row.rawHomework &&
    !row.rawAttendance &&
    !row.rawParticipation &&
    !row.grade &&
    !row.comments &&
    !row.rawGradeSentiment);

const isEmptyStudentSet = (set: StudentSet): boolean => {
  if (set.context.subject || set.context.name) {
    return false;
  }
  const emptyStudents = set.students.every(isEmptyRow);
  return emptyStudents;
};

export { ID, isEmptyRow, isEmptyStudentSet };
