import { FC, ReactNode } from "react";
import styled from "@emotion/styled";

// transparent button with no background or border
export const BaseButton = styled.button`
  background-color: transparent;
  border: none;
  color: #4f4f4f;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

type IconButtonProps = {
  label: string;
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
};

const IconButton: FC<IconButtonProps> = (props) => {
  return (
    <BaseButton
      onClick={props.onClick}
      title={props.label}
      disabled={props.disabled}
    >
      {props.children}
    </BaseButton>
  );
};

export default IconButton;
