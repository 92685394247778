type Row = string[];
type Sheet = Row[];

const CSV = (() => {
  const parse = (text: string): Sheet => {
    const textRows = text.toString().split("\n");

    const sheet: Sheet = [];
    for (const rowText of textRows) {
      const row: Row = [];
      let cell = "";
      let inQuote = false;
      for (const char of rowText) {
        if (char === '"') {
          inQuote = !inQuote;
        } else if (char === "," && !inQuote) {
          row.push(cell);
          cell = "";
        } else {
          cell += char;
        }
      }
      if (cell !== "") {
        row.push(cell);
      }
      if (row.length > 0) {
        sheet.push(row);
      }
    }

    // filter out empty rows
    return sheet.filter((row) => row.length > 0);
  };

  const cleanCell = (cell: string): string => {
    return cell
      .replace(/\n/g, " ")
      .replace(/\s\s+/g, " ")
      .replace(/"/g, "'")
      .trim();
  };

  const stringify = (sheet: Sheet): string => {
    let text = "";
    for (const row of sheet) {
      for (const dirtyCell of row) {
        const cell = cleanCell(dirtyCell);
        text += `"${cell}",`;
      }
      text = text.slice(0, -1);
      text += "\n";
    }
    return text;
  };

  return {
    parse,
    stringify,
  };
})();

export default CSV;
