// file upload component using dropzone
import { FC, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Span, Strong } from "./Typography";
import { MdUpload } from "./Icons";

type UploadProps = {
  onUpload: (file: File) => void;
};

const UploadLabel = styled.label`
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: #4f4f4f;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 2rem;
  flex-direction: column;
  gap: 1rem;
`;

const UploadStrong = styled(Strong)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dropzone = styled.div`
  width: 100%;

  border: 2px dashed #ccc;
  border-radius: 0.5rem;

  background-color: #ffffee;
`;

const HiddenInput = styled.input`
  display: none;
`;

const Upload: FC<UploadProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener("change", (e) => {
        const { files } = e.target as HTMLInputElement;
        if (files && files.length > 0) {
          props.onUpload(files[0]);
        }
      });
    }
  }, [inputRef]);

  // add drag and drop functionality
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    if (files && files.length > 0) {
      props.onUpload(files[0]);
    }
  };

  return (
    <Dropzone
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <UploadLabel htmlFor="upload">
        <UploadStrong>
          <MdUpload size={24} /> Upload a CSV file
        </UploadStrong>
        <Span>Drag and drop a CSV file here, or click to select a file.</Span>
      </UploadLabel>
      <HiddenInput id="upload" type="file" ref={inputRef} accept=".csv" />
    </Dropzone>
  );
};

export default Upload;
