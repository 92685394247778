import { FC, useEffect } from "react";
import { Box, Heading1, Paragraph } from "../components";
import { Link } from "react-router-dom";

const NotFound404View: FC = () => {
  useEffect(() => {
    document.title = "Reports Writer | Not Found";
  }, []);

  return (
    <Box
      column
      gap="1rem"
      flex={1}
      padding="4rem"
      maxWidth="40rem"
      margin="0 auto"
      alignItems="center"
    >
      <Heading1>Page Not Found</Heading1>
      <Paragraph>
        The page you are looking for does not exist.{" "}
        <Link to="/">Go back to the homepage</Link>.
      </Paragraph>
    </Box>
  );
};

export default NotFound404View;
