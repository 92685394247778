import { FC, useEffect } from "react";
import { Heading1, Heading2, Box, SpacedParagraph, Paragraph, Span, Heading3 } from "../../components";
import { Link } from "react-router-dom";

const TheProblemWithReportCommentBanksBlogPostView: FC = () => {
    useEffect(() => {
        document.title = "Reports Writer | The Problem With Report Comment Banks";
    }, []);

    return (
        <Box column gap="1rem" flex={1} padding="4rem" maxWidth="40rem" margin="0 auto">
            <Box row>
                <Link to="/blog"><Span>Back</Span></Link>
            </Box>
            <Heading1>The Problem With Report Comment Banks</Heading1>
            <Box row justifyContent="space-between">
                <Paragraph>
                    by James O'Toole
                </Paragraph>
                <Paragraph>
                    1st Nov 2022
                </Paragraph>
            </Box>
            <SpacedParagraph>
                Report card comment banks are not very good for a number of reasons. They are often repetitive and lack variety. This can make it difficult for teachers to find the right words to describe a student’s progress or achievement.
                Secondly, comment banks can be formulaic and lack the personal touch that teachers can provide when writing their own comments. 
            </SpacedParagraph>
            <SpacedParagraph>
                Comment banks can be time-consuming to search through, particularly if teachers are looking for something specific.
                It can be frustrating to use if they are not well-organized or if they do not contain the type of comments that teachers are looking for.
            </SpacedParagraph>
            <SpacedParagraph>
                Though comment banks can be a quick way to create reports, they are often repetitive and unpersonalised.
                This can lead to teachers getting in trouble for using them, as their reports may be less tailored to each individual student.
                Most teachers don't use comment banks, instead opting to write reports from scratch.
                This can be a more time-consuming process, but it allows for more personalisation.
            </SpacedParagraph>
            <SpacedParagraph>
                Modern tools like Reports Writer are better than comment banks, as they use artificial intelligence to allow for a personal touch.
                Comments are generated based on data entered about each student, meaning that each report is unique.
                This can save teachers a lot of time, as well as ensuring that reports are personalised.
            </SpacedParagraph>
            <SpacedParagraph>
                I hope that this has given you some ideas on how to make report writing a little easier for you.
                I know that there are hundreds of reports to be written over the next few months and I wish you all the best of luck, whether you are hand writing, typing or generating your reports this year.
                Thank you for all that you do for your students.
            </SpacedParagraph>
            <Heading3>Read Next</Heading3>
            <Box column gap="1rem">
                <Paragraph>
                    <Link to="/blog/are-school-reports-worth-it">
                        Are School Reports Worth It?
                    </Link>
                </Paragraph>
                <Paragraph>
                    <Link to="/blog/how-much-time-do-teachers-spend-on-reports">
                        How Much Time Do Teachers Spend On Reports?
                    </Link>
                </Paragraph>
            </Box>
        </Box>
    );
};

export { TheProblemWithReportCommentBanksBlogPostView };
