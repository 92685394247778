const AWS_REGION = "us-east-1";
const AUTH_DOMAIN = "reportswriter.auth.us-east-1.amazoncognito.com";
const AWS_USER_POOL_WEB_CLIENT_ID = "4bfp11a4o60lg45fsk6ei4p1oi";
const AWS_USER_POOL_ID = `us-east-1_IDaGQYzm2`;

let origin = "https://www.reportswriter.com";
try {
  origin = window.location.origin;
} catch (e) {
  // do nothing
}

const LOGIN_URL = `https://${AUTH_DOMAIN}/login?client_id=${AWS_USER_POOL_WEB_CLIENT_ID}&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${encodeURIComponent(
  origin
)}/`;
const SIGNUP_URL = `https://${AUTH_DOMAIN}/signup?client_id=${AWS_USER_POOL_WEB_CLIENT_ID}&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${encodeURIComponent(
  origin
)}/`;

const API_ENDPOINT = `https://cnf6lm6cfc.execute-api.us-east-1.amazonaws.com/prod`;
const API_NAME = `tindsit-api`;

export {
  LOGIN_URL,
  SIGNUP_URL,
  AWS_REGION,
  AWS_USER_POOL_WEB_CLIENT_ID,
  AUTH_DOMAIN,
  AWS_USER_POOL_ID,
  API_ENDPOINT,
  API_NAME,
};
