import { FC, ReactNode } from "react";
import styled from "@emotion/styled";

export type ButtonProps = {
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
  secondary?: boolean;
};

// background-color: #4caf50;
export const BaseButton = styled.button<{ secondary?: boolean }>`
  margin: 0;
  cursor: pointer;
  padding: 1rem;
  text-align: center;
  height: auto;
  font-size: 1rem;
  border-radius: 0.5rem;
  font-family: "Roboto", sans-serif;
  padding: 0.75rem 1rem;
  box-shadow: 1px 1px 0.5rem #ddd;
  cursor: pointer;

  background-color: ${(props) => (props.secondary ? "#fff" : "#0074D9")};
  color: ${(props) => (props.secondary ? "#222" : "#fff")};
  border: ${(props) => (props.secondary ? "1px solid #ccc" : "1px solid #0074D9")};
  &:hover {
    background-color: ${(props) => (props.secondary ? "#eee" : "#0062cc")};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const Button: FC<ButtonProps> = ({
  disabled,
  children,
  onClick,
  secondary,
}) => {
  return (
    <BaseButton disabled={disabled} onClick={onClick} secondary={secondary}>
      {children}
    </BaseButton>
  );
};

export default Button;
