import { FC } from "react";
import logo from "./logo.webp";
import {
  Box,
  Footer,
  Main,
  NavBar,
  NavItem,
  Reset,
  Span,
  useSession,
} from "./components";
import { LOGIN_URL, SIGNUP_URL } from "./constants";
import { Link, Outlet } from "react-router-dom";

const AppWrapper: FC = (props) => {
  const session = useSession();
  return (
    <Box column flex={1}>
      <Reset />
      <NavBar src={logo} alt="Reports Writer" to="/">
        {session.loggedIn ? (
          <>
            <NavItem to="/sets">Student Sets</NavItem>
            <NavItem to="/pricing">Pricing</NavItem>
            <NavItem to="/account">Account</NavItem>
          </>
        ) : (
          <>
            <NavItem to={SIGNUP_URL}>Sign Up</NavItem>
            <NavItem to={LOGIN_URL}>Log In</NavItem>
            <NavItem to="/pricing">Pricing</NavItem>
            <NavItem to="/sets">Student Sets</NavItem>
          </>
        )}
      </NavBar>
      <Main>
        <Outlet />
      </Main>
      <Footer>
        <Box column center flex={1}>
          <Link to="/blog">
            <Span>Blog</Span>
          </Link>
        </Box>
        <Box column center flex={1}>
          <Link to="/privacy">
            <Span>Privacy Policy</Span>
          </Link>
        </Box>
        <Box column center flex={1}>
          <Link to="/contact">
            <Span>Contact Support</Span>
          </Link>
        </Box>
      </Footer>
    </Box>
  );
};

export default AppWrapper;
