import { FC } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  Heading2,
  IconButton,
  Paragraph,
  Select,
} from "../components";
import { MdEdit } from "../components/Icons";
import { Student, StudentSet } from "../types";

type ChooseStudentProps = {
  studentSet: StudentSet;
  student: Student;
  students: Student[];
  onSelectStudent: (student: Student) => void;
};

const ChooseStudent: FC<ChooseStudentProps> = (props) => {
  const navigate = useNavigate();
  return (
    <Card>
      <Box row justifyContent="space-between" alignItems="center">
        <Heading2>{props.studentSet.context.name || "Untitled Set"}</Heading2>
        <IconButton label="Change Set" onClick={() => navigate("/sets")}>
          <Box gap=".5rem" wrap={false} center>
            <MdEdit size={24} />
            Change Set
          </Box>
        </IconButton>
      </Box>
      <Select
        options={props.students.map((student) => ({
          label: student?.name
            ? `${student?.name} ${student?.surname}`
            : "Un-named",
          ...student,
        }))}
        onChange={(s) => props.onSelectStudent(s)}
        label={"Select a student"}
        value={{
          ...props.student,
          label: `${props.student?.name} ${props.student?.surname}`,
        }}
      />
    </Card>
  );
};

export { ChooseStudent };
