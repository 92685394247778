import { usePost } from "./useRestApi";
import { Currency, PaymentLinkBody } from "../types";

const usePostPaymentLinks = () => {
  const { data, loading, error, post } = usePost<PaymentLinkBody, string>({
    url: "/payment-links",
  });

  return { url: data, loading, error, post };
};

export { usePostPaymentLinks };
