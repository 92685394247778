import produce from "immer";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  GradeSystem,
  Pronoun,
  Selected,
  Store,
  Student,
  StudentSet,
  StudyContext,
  Currency,
} from "../types";
import { ID, isEmptyStudentSet, isEmptyRow } from "../utils";
import useGradeSentiment from "./useGradeSentiment";

const initial: Store = {
  studentSets: [
    {
      id: ID(),
      context: { name: "", subject: "", gradeSystem: GradeSystem.UK },
      students: [
        {
          id: ID(),
          name: "",
          surname: "",
          rawPronoun: "",
          pronoun: null,
          rawHomework: "",
          homework: null,
          rawAttendance: "",
          attendance: null,
          rawParticipation: "",
          participation: null,
          grade: "",
          rawGradeSentiment: "",
          gradeSentiment: null,
          comments: "",
          reports: [],
        },
      ],
    },
  ],
};

let cacheCurrency: Currency = "GBP";
let cacheStore: Partial<Store> = {};
let cacheSelected: Partial<Selected> = {};

const cacheStoreKey = "reports-writer-cache";
const cacheSelectedKey = "reports-writer-selected";
const cacheCurrencyKey = "reports-writer-currency";

const parseDigit = (
  value: string
): 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | null => {
  const parsed: number = parseInt(value, 10);
  if (isNaN(parsed) || parsed < 1 || parsed > 9) {
    return null;
  }
  return parsed as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
};

const parseCurrencyCache = (incoming: any): Currency => {
  switch (incoming) {
    case "GBP":
      return "GBP";
    case "USD":
      return "USD";
    case "AUD":
      return "AUD";
    default:
      return "GBP";
  }
};

const parseSelectedCache = (incoming: any): Selected => ({
  studentSetId: incoming?.studentSetId ?? initial.studentSets[0].students[0].id,
  studentId: incoming?.studentId ?? initial.studentSets[0].students[0].id,
});

const parseStoreCache = (incoming: any): Store => {
  if (!incoming || !incoming.studentSets) {
    return initial;
  }

  const studentSets =
    incoming.studentSets.map((set: any) => {
      const context: StudyContext = {
        name: set?.context?.name || initial.studentSets[0].context.name,
        subject:
          set?.context?.subject || initial.studentSets[0].context.subject,
        gradeSystem:
          set?.context?.gradeSystem ||
          initial.studentSets[0].context.gradeSystem,
      };

      const students = set?.students?.map((student: any) => {
        const reports = student.reports.map((report: any) => ({
          id: report.id,
          date: new Date(report.date),
          content: report.content,
        }));

        return {
          id: student.id,
          name: student.name || initial.studentSets[0].students[0].name,
          surname:
            student.surname || initial.studentSets[0].students[0].surname,
          rawPronoun:
            student.rawPronoun || initial.studentSets[0].students[0].rawPronoun,
          pronoun:
            student.pronoun || initial.studentSets[0].students[0].pronoun,
          rawHomework:
            student.rawHomework ||
            initial.studentSets[0].students[0].rawHomework,
          homework:
            student.homework || initial.studentSets[0].students[0].homework,
          rawAttendance:
            student.rawAttendance ||
            initial.studentSets[0].students[0].rawAttendance,
          attendance:
            student.attendance || initial.studentSets[0].students[0].attendance,
          rawParticipation:
            student.rawParticipation ||
            initial.studentSets[0].students[0].rawParticipation,
          participation:
            student.participation ||
            initial.studentSets[0].students[0].participation,
          grade: student.grade || initial.studentSets[0].students[0].grade,
          rawGradeSentiment:
            student.rawGradeSentiment ||
            initial.studentSets[0].students[0].rawGradeSentiment,
          gradeSentiment:
            student.gradeSentiment ||
            initial.studentSets[0].students[0].gradeSentiment,
          comments:
            student.comments || initial.studentSets[0].students[0].comments,
          reports,
        };
      });

      return {
        id: set.id || ID(),
        context: context,
        students: students.length ? students : initial.studentSets[0].students,
      };
    }) || initial.studentSets;

  return {
    studentSets,
  };
};

const parsePronoun = (rawPronoun: string): Pronoun | null => {
  let pronoun: Pronoun | null = null;
  if (rawPronoun.toLowerCase().includes("she")) {
    pronoun = Pronoun.She;
  } else if (rawPronoun.toLowerCase().includes("he")) {
    pronoun = Pronoun.He;
  } else if (rawPronoun.toLowerCase().includes("they")) {
    pronoun = Pronoun.They;
  }
  return pronoun;
};

const findSet = (store: Store, setId: string): StudentSet | null =>
  store.studentSets.find((set) => set.id === setId) ?? null;
const findStudent = (set: StudentSet, studentId: string): Student | null =>
  set.students.find((student) => student.id === studentId) ?? null;
// const findReport = (student: Student, reportId: string): Report | null => student.reports.find(report => report.id === reportId) ?? null;

const useStore = () => {
  const [currency, setCurrency] = useState<Currency>("GBP");
  const [store, setStore] = useState<Store>(initial);
  const [selected, setSelected] = useState<Selected>({
    studentId: store.studentSets[0].students[0].id,
    studentSetId: store.studentSets[0].id,
  });

  useEffect(() => {
    cacheStore = store;
    cacheSelected = selected;
    cacheCurrency = currency;
  }, [store, selected, currency]);

  useEffect(() => {
    const storeData = localStorage.getItem(cacheStoreKey);
    const selectedData = localStorage.getItem(cacheSelectedKey);
    const currencyData = localStorage.getItem(cacheCurrencyKey);
    if (storeData) {
      const obj = parseStoreCache(JSON.parse(storeData));
      setStore(obj);
      cacheStore = obj;
    }
    if (selectedData) {
      const obj = parseSelectedCache(JSON.parse(selectedData));
      setSelected(obj);
      cacheSelected = obj;
    }
    if (currencyData) {
      const obj = parseCurrencyCache(JSON.parse(currencyData));
      setCurrency(obj);
      cacheCurrency = obj;
    }

    const write = () => {
      localStorage.setItem(cacheStoreKey, JSON.stringify(cacheStore));
      localStorage.setItem(cacheSelectedKey, JSON.stringify(cacheSelected));
      localStorage.setItem(cacheCurrencyKey, JSON.stringify(cacheCurrency));
    };
    const interval = setInterval(write, 200);

    return () => {
      write();
      clearInterval(interval);
    };
  }, []);

  const studentSets = useMemo(() => {
    const sets = [] as StudentSet[];
    let includesEmpty = false;
    store.studentSets.forEach((set) => {
      if (isEmptyStudentSet(set)) {
        if (!includesEmpty) {
          sets.push(set);
          includesEmpty = true;
        }
      } else {
        sets.push(set);
      }
    });
    return sets;
  }, [store]);

  const studentSet = useMemo(() => {
    return (
      studentSets.find((set) => set.id === selected.studentSetId) ??
      studentSets[0]
    );
  }, [studentSets, selected.studentSetId]);

  const context = useMemo(() => {
    return studentSet?.context ?? initial.studentSets[0].context;
  }, [studentSet]);

  const students = useMemo(() => {
    if (!studentSet?.students) {
      return initial.studentSets[0].students;
    }
    return [...studentSet.students].sort(
      (a, b) =>
        a.surname.localeCompare(b.surname) || a.name.localeCompare(b.name)
    );
  }, [studentSet]);

  const student = useMemo(() => {
    const s = students.find((s) => s.id === selected.studentId);
    if (s) {
      return s;
    }
    return {
      id: ID(),
      name: "",
      surname: "",
      rawPronoun: "",
      pronoun: null,
      rawHomework: "",
      homework: null,
      rawAttendance: "",
      attendance: null,
      rawParticipation: "",
      participation: null,
      grade: "",
      rawGradeSentiment: "",
      gradeSentiment: null,
      comments: "",
      reports: [],
    } as Student;
  }, [students, selected.studentId]);

  useEffect(() => {
    if (!findStudent(studentSet, selected.studentId)) {
      setSelected({
        studentId: students[0].id,
        studentSetId: studentSet.id,
      });
    }
  }, [student, students, studentSet]);

  const reports = useMemo(() => {
    if (!student?.reports) {
      return [];
    }
    return [...student.reports].sort(
      (a, b) => b.date.getTime() - a.date.getTime()
    );
  }, [student]);

  const onSubjectChange = useCallback(
    (subject: string) => {
      setStore(
        produce((draft) => {
          const set = findSet(draft, selected.studentSetId);
          if (set) {
            set.context.subject = subject;
          }
        })
      );
    },
    [selected.studentSetId]
  );

  const onSetNameChange = useCallback(
    (name: string) => {
      setStore(
        produce((draft) => {
          const set = findSet(draft, selected.studentSetId);
          if (set) {
            set.context.name = name;
          }
        })
      );
    },
    [selected.studentSetId]
  );

  const onGradeSystemChange = useCallback(
    (gradeSystem: GradeSystem) => {
      setStore(
        produce((draft) => {
          const set = findSet(draft, selected.studentSetId);
          if (set) {
            set.context.gradeSystem = gradeSystem;
          }
        })
      );
    },
    [selected.studentSetId]
  );

  const onNameChange = useCallback(
    (name: string) => {
      setStore(
        produce((draft) => {
          const set = findSet(draft, selected.studentSetId);
          if (set) {
            const s = findStudent(set, selected.studentId);
            if (s) {
              s.name = name;
            }
          }
        })
      );
    },
    [selected.studentId, selected.studentSetId]
  );

  const onSurnameChange = useCallback(
    (surname: string) => {
      setStore(
        produce((draft) => {
          const set = findSet(draft, selected.studentSetId);
          if (set) {
            const s = findStudent(set, selected.studentId);
            if (s) {
              s.surname = surname;
            }
          }
        })
      );
    },
    [selected.studentId, selected.studentSetId]
  );

  const onPronounChange = useCallback(
    (rawPronoun: string) => {
      setStore(
        produce((draft) => {
          const set = findSet(draft, selected.studentSetId);
          if (set) {
            const s = findStudent(set, selected.studentId);
            if (s) {
              s.rawPronoun = rawPronoun;
              s.pronoun = parsePronoun(rawPronoun);
            }
          }
        })
      );
    },
    [selected.studentId, selected.studentSetId]
  );

  const onHomeworkChange = useCallback(
    (rawHomework: string) => {
      setStore(
        produce((draft) => {
          const set = findSet(draft, selected.studentSetId);
          if (set) {
            const s = findStudent(set, selected.studentId);
            if (s) {
              s.rawHomework = rawHomework;
              s.homework = parseDigit(rawHomework);
            }
          }
        })
      );
    },
    [selected.studentId, selected.studentSetId]
  );

  const onParticipationChange = useCallback(
    (rawParticipation: string) => {
      setStore(
        produce((draft) => {
          const set = findSet(draft, selected.studentSetId);
          if (set) {
            const s = findStudent(set, selected.studentId);
            if (s) {
              s.rawParticipation = rawParticipation;
              s.participation = parseDigit(rawParticipation);
            }
          }
        })
      );
    },
    [selected.studentId, selected.studentSetId]
  );

  const onAttendanceChange = useCallback(
    (rawAttendance: string) => {
      setStore(
        produce((draft) => {
          const set = findSet(draft, selected.studentSetId);
          if (set) {
            const s = findStudent(set, selected.studentId);
            if (s) {
              s.rawAttendance = rawAttendance;
              s.attendance = parseDigit(rawAttendance);
            }
          }
        })
      );
    },
    [selected.studentId, selected.studentSetId]
  );

  const onGradeChange = useCallback(
    (gradeStr: string) => {
      setStore(
        produce((draft) => {
          const set = findSet(draft, selected.studentSetId);
          if (set) {
            const s = findStudent(set, selected.studentId);
            if (s) {
              s.grade = gradeStr;
              if (s.grade && set.context?.gradeSystem != null) {
                s.gradeSentiment = useGradeSentiment(
                  s.grade,
                  set.context.gradeSystem
                );
                s.rawGradeSentiment = s.gradeSentiment?.toString() ?? "";
              }
            }
          }
        })
      );
    },
    [selected.studentId, selected.studentSetId, studentSet?.context.gradeSystem]
  );

  const onGradeSentimentChange = useCallback(
    (rawGradeSentiment: string) => {
      const gradeSentiment = parseDigit(rawGradeSentiment);
      setStore(
        produce((draft) => {
          const set = findSet(draft, selected.studentSetId);
          if (set) {
            const s = findStudent(set, selected.studentId);
            if (s) {
              s.rawGradeSentiment = rawGradeSentiment;
              s.gradeSentiment = gradeSentiment;
            }
          }
        })
      );
    },
    [selected.studentId, selected.studentSetId]
  );

  const onCommentChange = useCallback(
    (comments: string) => {
      setStore(
        produce((draft) => {
          const set = findSet(draft, selected.studentSetId);
          if (set) {
            const s = findStudent(set, selected.studentId);
            if (s) {
              s.comments = comments;
            }
          }
        })
      );
    },
    [selected.studentId, selected.studentSetId]
  );

  const onSelectStudentSet = useCallback(
    (studentSetId: string) => {
      const set = findSet(store, studentSetId);
      if (set) {
        const s = set.students[0];
        setSelected({
          studentId: s?.id ?? "",
          studentSetId,
        });
      }
    },
    [store]
  );

  const onSelectStudent = useCallback(
    (studentId: string) => {
      const set = findSet(store, selected.studentSetId);
      if (set) {
        const s = findStudent(set, studentId);

        setSelected({
          studentId: s?.id ?? "",
          studentSetId: selected.studentSetId,
        });
      }
    },
    [selected.studentSetId, store]
  );

  const onAddStudentSet = useCallback(() => {
    const sets = store.studentSets;
    const emptySet = sets.find((s) => isEmptyStudentSet(s));
    if (emptySet) {
      setSelected({
        studentId: emptySet.students[0]?.id ?? "",
        studentSetId: emptySet?.id,
      });
    } else {
      const setId = ID();
      const studentId = ID();
      setStore(
        produce((draft) => {
          draft.studentSets.push({
            id: setId,
            context: {
              name: "",
              subject: "",
              gradeSystem: GradeSystem.UK,
            },
            students: [
              {
                id: studentId,
                name: "",
                surname: "",
                rawPronoun: "",
                pronoun: null,
                rawHomework: "",
                homework: null,
                rawAttendance: "",
                attendance: null,
                rawParticipation: "",
                participation: null,
                grade: "",
                rawGradeSentiment: "",
                gradeSentiment: null,
                comments: "",
                reports: [],
              },
            ],
          });
        })
      );
      setSelected({
        studentId: studentId,
        studentSetId: setId,
      });
    }
  }, [store.studentSets]);

  const onImportStudents = useCallback(
    (rawStudents: Student[]) => {
      const students = rawStudents
        .map((student) => {
          const grade = student.grade ?? "";
          const defaultGradeSentiment = useGradeSentiment(
            student.grade,
            studentSet?.context.gradeSystem ?? GradeSystem.UK
          );
          const rawGradeSentiment =
            student.rawGradeSentiment ??
            defaultGradeSentiment?.toString() ??
            "";
          const gradeSentiment = parseDigit(rawGradeSentiment);
          return {
            ...student,
            id: ID(),
            pronoun: parsePronoun(student.rawPronoun),
            participation: parseDigit(student.rawParticipation),
            homework: parseDigit(student.rawHomework),
            attendance: parseDigit(student.rawAttendance),
            grade,
            rawGradeSentiment,
            gradeSentiment,
          };
        })
        .filter((student) => !isEmptyRow(student));

      setStore(
        produce((draft) => {
          const set = findSet(draft, selected.studentSetId);
          if (set) {
            set.students = set.students
              .filter((s) => !isEmptyRow(s))
              .concat(students);
          }
        })
      );
    },
    [selected.studentSetId, studentSet?.context.gradeSystem]
  );

  const onAddStudent = useCallback(() => {
    const set = findSet(store, selected.studentSetId);
    const lastStudent = set?.students[set.students.length - 1];
    if (lastStudent && isEmptyRow(lastStudent)) {
      setSelected({
        studentId: lastStudent.id,
        studentSetId: set.id,
      });
    } else if (set) {
      const studentId = ID();
      setStore(
        produce((draft) => {
          const _set = findSet(draft, selected.studentSetId);
          if (_set) {
            _set.students.push({
              id: studentId,
              name: "",
              surname: "",
              rawPronoun: "",
              pronoun: null,
              rawHomework: "",
              homework: null,
              rawParticipation: "",
              participation: null,
              rawAttendance: "",
              attendance: null,
              grade: "",
              rawGradeSentiment: "",
              gradeSentiment: null,
              comments: "",
              reports: [],
            });
          }
        })
      );
      setSelected({
        studentId,
        studentSetId: set.id,
      });
    }
  }, [selected.studentSetId, store.studentSets, store]);

  const onAddReport = useCallback(
    (content: string) => {
      setStore(
        produce((draft) => {
          const set = findSet(draft, selected.studentSetId);
          if (set) {
            const s = findStudent(set, selected.studentId);
            if (s) {
              s.reports.push({
                id: ID(),
                date: new Date(),
                content,
              });
            }
          }
        })
      );
    },
    [selected.studentId, selected.studentSetId]
  );

  const onCurrencyChange = useCallback(
    (currency: Currency) => {
      setCurrency(currency);
    },
    [setCurrency]
  );

  return {
    studentSets,

    studentSet,
    context,
    students,

    student,
    reports,

    onSubjectChange,
    onSetNameChange,
    onGradeSystemChange,

    onNameChange,
    onSurnameChange,
    onPronounChange,
    onHomeworkChange,
    onParticipationChange,
    onAttendanceChange,
    onGradeChange,
    onGradeSentimentChange,
    onCommentChange,

    onSelectStudentSet,
    onSelectStudent,

    onAddStudentSet,
    onAddStudent,
    onAddReport,
    onImportStudents,

    currency,
    onCurrencyChange,
  };
};

export default useStore;
